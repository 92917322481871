const orderingRoutes = {
  ordering: 'ordering',
  containers: {
    scan: 'scan',
    menu: 'menu',
    category: 'category',
    restaurant: 'restaurant',
    cart: 'cart',
    confirmation: 'confirmation',
    payment: 'payment',
    orders: 'orders',
    account: 'account',
    welcome: 'welcome',
    offers: 'offers',
    search: 'search',
    menuOffer: 'menu-offer',
    rewardList: 'list',
    menuItemOffer: 'menu-item-offer',
  },
  offers: {
    offerList: 'list',
    availOffer: 'avail-offer',
  },
};

export default orderingRoutes;
