import React, { useRef } from 'react';
import classNames from 'classnames';
import { BeatLoader } from 'halogenium';
import { fudrThemeColor, engagePrimaryThemeColor } from '../../../constants/globals';
import { constants } from '../../../constants/configuration';
import routes from '../../../routes';
import { getTimeSalutation, toTitleCase } from '../../../utils/helpers';
import { SplashScreen } from '../SplashScreen';
import greetingSplashScreenBgImage from '../../../assets/images/greeting-splash-screen-bg.png';
import engageGreetingSplashScreenBgImage from '../../../assets/images/engage-greeting-splash-screen-bg.svg';
import dealsSplashScreenBgImage from '../../../assets/images/deals-splash-screen.svg';

import './Greeting.scss';

const greetingSplashScreenResources = constants.greetingSplashScreen;

const currentTime = new Date();

export const Greeting = ({
  active,
  type,
  timeout,
  direction,
  onEntered,
  onExited,
  splashDuration,
  splashBgColor,
  splashBgImage,
  textColor,
  deactivate,
  showTimeSalutation,
  loggedInGuestName,
  revisit,
  outletName,
  container,
}) => {
  const containerRef = useRef(null);

  const getWelcomeText = (revisit = false) =>
    revisit
      ? greetingSplashScreenResources.welcomeBackPrefix
      : greetingSplashScreenResources.welcomePrefix;

  const baseAnimationProps = {
    type,
    timeout,
    direction,
    onEntered,
    onExited,
  };

  const splashScreenProps = {
    active,
    bgColor: splashBgColor,
    textColor,
    containerRef,
    splashDuration,
    deactivate,
  };

  const getSpashScreenBgImage = () => {
    switch (container) {
      case routes.containers.welcome:
        return splashBgImage;
      case routes.containers.feedback:
        return engageGreetingSplashScreenBgImage;
      default:
        return dealsSplashScreenBgImage;
    }
  };

  const getLoaderThemeColor = (container) => {
    switch (container) {
      case routes.containers.welcome:
        return fudrThemeColor;
      default:
        return engagePrimaryThemeColor;
    }
  };

  const timeSalutation = getTimeSalutation(currentTime);
  const welcomeText = getWelcomeText(revisit);
  const commonWelcomeScreenContainers = [routes.containers.welcome, routes.containers.feedback];
  const showWelcomeGreetingFlag = welcomeText && commonWelcomeScreenContainers.includes(container); // Show welcome greeting only when the outlet name and the welcome text are populated
  return (
    <div className="greeting-splash-screen" ref={containerRef}>
      <SplashScreen {...splashScreenProps} baseAnimation={baseAnimationProps}>
        <div className={classNames('greeting-splash-screen_bg-image-wrapper', container)}>
          <img src={getSpashScreenBgImage()} alt="" className="bg-image" />
        </div>
        <div className={classNames('greeting-splash-screen_content', container)}>
          {showTimeSalutation && <div className="time-salutation">{timeSalutation}</div>}
          {loggedInGuestName && <div className="guest-name">{toTitleCase(loggedInGuestName)}</div>}
          <div className="greeting-splash-screen_content_greeting-text">
            {commonWelcomeScreenContainers.includes(container) && showWelcomeGreetingFlag ? (
              <>
                <div>{welcomeText}</div>
                <div>{outletName}</div>
              </>
            ) : (
              <>
                <div>
                  Preparing some exciting <br /> deals for you
                </div>
                <BeatLoader
                  className="greeting-splash-screen_content_greeting-text_loader"
                  color={getLoaderThemeColor(container)}
                  size="25px"
                />
              </>
            )}
          </div>
        </div>
      </SplashScreen>
    </div>
  );
};

Greeting.defaultProps = {
  active: false,
  type: 'fade',
  timeout: { appear: 0, enter: 0, exit: 1200 }, // ms
  direction: 'left',
  onEntered: () => {},
  onExited: () => {},
  splashBgColor: fudrThemeColor,
  splashBgImage: greetingSplashScreenBgImage,
  textColor: '#fff',
  splashDuration: 3000, // ms
  deactivate: () => {},
  showTimeSalutation: true,
  container: '',
};
