// index reducer, one reducer to rule them all

import { combineReducers } from 'redux';

import app from './app.reducer';
import rewardReducer from './rewards';
import orderingReducer from './ordering';
import commonReducer from './common';
import authenticationReducer from './authentication';

const rootReducer = combineReducers({
  app,
  rewards: rewardReducer,
  ordering: orderingReducer,
  common: commonReducer,
  authentication: authenticationReducer
});

export default rootReducer;
