export const DISPATCH_GUEST_DATA = 'DISPATCH_GUEST_DATA';

export const BASE = 'BASE/';

export const CLEAR_GUEST_DATA = 'CLEAR_GUEST_DATA';

export const ATTEMPT_LOGIN_SUCCESS = `${BASE} ATTEMPT_LOGIN_SUCCESS`;

export const DISPATCH_AUTH_TOKEN = `${BASE} DISPATCH_AUTH_TOKEN`;

export const DISPATCH_SIGNIN_DATA = `${BASE} DISPATCH_SIGNIN_DATA`;

export const CREATE_BRAND_GUEST = 'CREATE_BRAND_GUEST';
