export const layoutConfiguration = [
  {
    version: 'L1',
    components: [
      { name: 'loyalty-card', version: 'cv1' },
      { name: 'outlet-list', version: 'cv2' },
      { name: 'recommended-menu-item-card', version: 'cv1' },
      { name: 'menu-item-card', version: 'cv1' },
    ],
  },
  {
    version: 'L2',
    components: [
      { name: 'loyalty-card', version: 'cv2' },
      { name: 'newly-launched-outlets', version: 'cv1' },
      { name: 'outlet-list', version: 'cv1' },
      { name: 'menu-category-section', version: 'cv1' },
      { name: 'recommended-menu-item-card', version: 'cv2' },
      { name: 'menu-item-card', version: 'cv2' },
    ],
  },
  { version: 'L3', components: [{ name: 'brand-promotion', version: 'cv1' }] },
  {
    version: 'L4',
    components: [
      { name: 'menu-page', version: 'cv4' },
      { name: 'recommended-menu-item-card', version: 'cv3' },
      { name: 'menu-item-card', version: 'cv3' },
      { name: 'menu-modal', version: 'cv2' },
      { name: 'previous-order-menu-item-card', version: 'cv2' },
      { name: 'material-elements', version: 'cv2' },
      { name: 'view-cart-popover', version: 'cv2' },
      { name: 'page-header', version: 'cv2' },
      { name: 'loyalty-card-mini', version: 'cv3' },
      { name: 'deal-card', version: 'cv2' },
      { name: 'guest-coupon-card', version: 'cv2' },
      { name: 'special-card', version: 'cv2' },
      { name: 'item-detail-popup', version: 'cv2' },
      { name: 'item-addon-popup', version: 'cv2' },
    ],
  },
];
