// calls all apis for ya

import Axios from 'axios';
import moment from 'moment';
import rootStore from '../store/store';
import { apiBaseUrl, constants } from '../constants/configuration';
import {
  defferedPingServerInSeconds,
  internetDisconnectivityHandledAPIEndpointBlacklist,
  internetErrorResponseStoreDispatchDelay,
  storageTypes,
} from '../constants/globals';
import { apiErrorResponseAction } from '../actions/app.action';
import { checkInternetConnectivity, isNativeWebView } from './helpers';

import { getItem, setItem } from './storage';
import { differenceBetween } from './fudrLocalizationHelper';

const { store } = rootStore;

const TYPE_JSON = 'application/json';

export const buildAPIInstance = ({ contentType = TYPE_JSON }) => {
  // set contextualTimeStamp with every axios request
  const contextualTimeStamp = moment().format();

  // wait for valid base url
  const objAxios = Axios.create({
    baseURL: apiBaseUrl,
    headers: {
      common: {
        Accept: TYPE_JSON,
        'Content-Type': contentType,
        'x-source-type': 'client',
      },
      post: {
        'Content-Type': TYPE_JSON,
      },
    },
  });

  objAxios.interceptors.request.use((config) => {
    if (!config.url) {
      return config;
    }

    const geoLocation = localStorage.getItem('geoLocation');
    if (geoLocation) {
      config.headers.common['location'] = geoLocation; // set updated geoLocation in req headers
    }
    // exempting internet connectivity logic for native as the same is already handled at the native level
    if (!isNativeWebView) {
      const requestURLPart = config.url;
      const isRequestURLBlacklisted = internetDisconnectivityHandledAPIEndpointBlacklist.some(
        (endpoint) => requestURLPart.indexOf(endpoint) > -1,
      );
      if (!isRequestURLBlacklisted) {
        const currentTimeStamp = getItem(storageTypes.sessionStorage, 'currentTimeStamp');
        const differenceInTimeStamps = differenceBetween(
          contextualTimeStamp,
          currentTimeStamp,
          'seconds',
        );
        if (differenceInTimeStamps > defferedPingServerInSeconds) {
          // update currentTimeStamp with latest time stamp
          setItem(storageTypes.sessionStorage, 'currentTimeStamp', moment().format());
          checkInternetConnectivity().then((isOnline) => {
            if (!isOnline) {
              // Generate a custom error response containing a user-friendly message to indicate internet disconnectivity.
              const errorResponse = {
                message: constants.globalErrorInfo.internetDisconnectivityErrorMessage,
              };
              console.error('Internet error response:', errorResponse);
              // Jump over all updates to errorResponse slice in redux store, if any, and then dispatch this one.
              setTimeout(() => {
                store.dispatch(apiErrorResponseAction(errorResponse));
              }, internetErrorResponseStoreDispatchDelay);
            }
          });
        }
      }
    }

    return config;
  });

  return objAxios;
};

const client = (params = {}) => buildAPIInstance(params);

export default client;
