import React, { useLayoutEffect } from 'react';

import { Animation } from '../Animation';

export const SplashScreen = (props) => {
  const onBaseEntered = (node) => {
    props.baseAnimation.onEntered(node);
    setTimeout(() => {
      if (props.brandSprite) {
        if (props.brandSprite.complete) {
          props.deactivate();
        } else {
          try {
            props.brandSprite.onload = () => {
              props.deactivate();
            };
          } catch {
            props.brandSprite.onerror = () => {
              props.deactivate();
            };
          }
        }
      } else {
        props.deactivate();
      }
    }, props.splashDuration);
  };

  // const onBaseEntered = (node) => {
  //   props.baseAnimation.onEntered(node);
  //   setTimeout(() => {
  //     props.deactivate();
  //   }, props.splashDuration);
  // };

  const onBaseExited = (node) => {
    props.baseAnimation.onExited(node);
  };

  useLayoutEffect(() => {}, [props.active]);

  const base = <div {...(props.className && { className: props.className })}>{props.children}</div>;

  return (
    <Animation
      {...props.baseAnimation}
      active={props.active}
      onEntered={onBaseEntered}
      onExited={onBaseExited}
      containerRef={props.containerRef}
    >
      {base}
    </Animation>
  );
};
