import {
  ATTEMPT_LOGIN_SUCCESS,
  CLEAR_GUEST_DATA,
  CREATE_BRAND_GUEST,
  DISPATCH_AUTH_TOKEN,
  DISPATCH_GUEST_DATA,
  DISPATCH_SIGNIN_DATA,
} from '../../actions/authentication/actionType';

const authentication = (state = {}, action = {}) => {
  if (action.type === DISPATCH_GUEST_DATA) {
    const { guestData } = action;
    return {
      ...state,
      guestData,
    };
  } else if (action.type === CLEAR_GUEST_DATA) {
    return {
      ...state,
      guestData: {},
    };
  } else if (action.type === ATTEMPT_LOGIN_SUCCESS) {
    const { loginData } = action;
    return {
      ...state,
      loginData,
    };
  } else if (action.type === DISPATCH_SIGNIN_DATA) {
    const { signIn } = action;
    return {
      ...state,
      signIn,
    };
  } else if (action.type === DISPATCH_AUTH_TOKEN) {
    const { authData } = action;
    return {
      ...state,
      authData,
    };
  } else if (action.type === CREATE_BRAND_GUEST) {
    const { brandGuest } = action;
    return {
      ...state,
      brandGuest,
    };
  }
  return state;
};

export default authentication;
