import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { calendarRelativeFormats } from './dateFormats';
import resourcesConfig from '../resources';
import { get, isEmpty } from 'lodash';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export const formatTime = (orderTime, time) => {
  let formattedTime = moment(orderTime).add(time, 'm').format('h:mm A');
  return formattedTime;
};

export const formatTimeTo12H = (time) => {
  if (!time || !moment(time, ['hh:mm']).isValid()) {
    return '';
  }

  let formattedTime = moment(time, ['hh:mm']).format('h:mm a');
  return formattedTime;
};

export const formatDate = (date, format = 'DD MMM YYYY') => {
  let formattedDate = moment(date).format(format);
  return formattedDate;
};

export const getTimeInterval = (time) => Date.now() + time;

// NOTE: Provide only those keys in relativeDayFormats which need to be overridden
export const formatCalenderDate = (dateTime, relativeDayFormats = {}) => {
  const overriddenRelativeDayFormats = !!relativeDayFormats
    ? { ...calendarRelativeFormats, ...relativeDayFormats }
    : calendarRelativeFormats;

  const { sameDay, nextDay, nextWeek, lastDay, lastWeek, sameElse } = overriddenRelativeDayFormats;

  return moment(dateTime).calendar(null, {
    sameDay,
    nextDay,
    nextWeek,
    lastDay,
    lastWeek,
    sameElse,
  });
};

export const formatCalendarDateTime = (dateTime, relativeDayFormats, includeTime = true) => {
  return `${formatCalenderDate(dateTime, relativeDayFormats)}${
    includeTime ? `, ${formatTime(dateTime)}` : ''
  }`;
};

export const formatDateTime = (date) => {
  let formattedDateTime = moment(date).format('DD MMM YYYY, LT');
  return formattedDateTime;
};

export const formatMinutesToHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours > 0 ? `${hours} hr ` : ''}${minutes > 0 ? `${minutes} min` : ''}`;
};

export const subtractFromCurrentDate = (years = 0, months = 0, days = 0) =>
  moment().subtract(years, 'y').subtract(months, 'M').subtract(days, 'd');

export const differenceBetween = (fromDate, toDate, unit = 'days') =>
  moment(fromDate).diff(moment(toDate), unit);

export const formatTransactionDate = (date, timeFirst = false) => {
  if (moment(date).isValid) {
    const now = moment();
    const inputDate = moment(date);
    const tempDate = moment(inputDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const daysAgo = now.diff(tempDate, 'days');

    if (daysAgo === 0) {
      // Today
      return inputDate.format(timeFirst ? 'h:mm a, [Today] ' : '[Today], h:mm a');
    } else if (daysAgo === 1) {
      // Yesterday
      return inputDate.format(timeFirst ? ', h:mm a, [Yesterday]' : '[Yesterday], h:mm a');
    } else if (daysAgo <= 6) {
      // Weekdays for past week
      return inputDate.format(timeFirst ? 'h:mm a, dddd' : 'dddd, h:mm a');
    } else {
      // DD MMM YYYY format
      return inputDate.format('DD MMM YYYY');
    }
  } else {
    return moment().format('h:mm A [Today]');
  }
};

export const formatDateToISO = (date) => {
  return moment(date).format(ISO_DATE_FORMAT);
};

export const formatDateToUTC = (date) => {
  let utcDate = moment(date).utc()._d.toISOString();
  return utcDate;
};

// Week starts from Monday and ends on Sunday
export const getLastWeek = () => {
  let fromDate = moment().subtract(1, 'weeks').startOf('week').add(1, 'day');
  let toDate = moment().subtract(1, 'weeks').endOf('week').add(1, 'day');

  return { fromDate, toDate };
};

export const getLastMonth = () => {
  let fromDate = moment().subtract(1, 'months').startOf('month');
  let toDate = moment().subtract(1, 'months').endOf('month');

  return { fromDate, toDate };
};

export const getStartOfMonth = () => {
  return moment().startOf('month');
};

// Week starts from Monday
export const getStartOfWeek = () => {
  return moment().startOf('week');
};

export const getTodayDate = () => {
  return moment();
};

export const isDateInvalid = (date, format = 'DD-MM-YYYY') => !moment(date, format, true).isValid();

export const renderOutletTiming = (outlet) => {
  const currentDay = moment().format('dddd').toUpperCase();
  const todayWorkHours =
    get(outlet, 'workhours', []).find((whour) => !whour.serviceType && whour.day === currentDay) ||
    {};
  if (todayWorkHours) {
    const startTimeIn12Hr = formatTimeTo12H(get(todayWorkHours, 'startTime'));
    const endTimeIn12Hr = formatTimeTo12H(get(todayWorkHours, 'endTime'));

    return `${startTimeIn12Hr} - ${endTimeIn12Hr}`;
  } else {
    return <FormattedMessage id="closed" defaultMessage={resourcesConfig.closed} />;
  }
};

export const renderCouponActiveRange = (startDate) => {
  const dateRange = formatDateTime(startDate);
  if (!isEmpty(dateRange)) {
    return (
      <li>
        <FormattedMessage
          id="validFrom"
          defaultMessage={resourcesConfig.validFrom}
          values={{ redeemableTime: dateRange }}
        />
      </li>
    );
  }
};

/**
 * @param {string} from
 * @param {string} to
 * @returns return true/false
 */
export const checkTimeInRange = (from, to, time) => {
  // Check if from or to time is undefined
  if (from === undefined || to === undefined) {
    return false;
  }
  // Parse input times using Moment.js
  const fromTime = moment(from, 'HH:mm');
  const toTime = moment(to, 'HH:mm');
  // Get current time using Moment.js
  const currentTime = !!time ? moment(time, 'HH:mm') : moment();
  // Check if 'from' time is greater than 'to' time (for ranges crossing midnight)
  let isCrossingMidnight = false;
  if (fromTime.isAfter(toTime)) {
    isCrossingMidnight = true;
  }

  // Check if current time falls within the range
  if (isCrossingMidnight) {
    if (currentTime.isSameOrAfter(fromTime) || currentTime.isSameOrBefore(toTime)) {
      return true;
    }
  } else {
    if (currentTime.isSameOrAfter(fromTime) && currentTime.isSameOrBefore(toTime)) {
      return true;
    }
  }

  return false;
};
