export const domain = 'fudr.in';
export const appName = 'FUDR';

// Get runtime environment variables from '_env' object attached to 'window' global object
const {
  API_URL,
  PAYTM_URL,
  CASHFREE_URL,
  GEOLOCATE_API_URL,
  INSTAGRAM_LINK,
  GOOGLE_API_KEY,
  TRUECALLER_APP_NAME,
  TRUECALLER_APP_KEY,
  GOOGLE_MAP_API_KEY,
  RECAPTCHA_SITE_KEY,
} = '_env' in window && window._env;

export const apiBaseUrl = `${API_URL}/restaurant-service`;
export const paytmUrl = PAYTM_URL;
export const cashfreeUrl = CASHFREE_URL;
export const googleAPIKey = GOOGLE_API_KEY;
export const googleMapAPIKey = GOOGLE_MAP_API_KEY;
export const geolocationApiUrl = GEOLOCATE_API_URL;
export const recaptchaSiteKey = RECAPTCHA_SITE_KEY;
export const socialHandlerLinks = {
  instagramLink: INSTAGRAM_LINK,
};
export const trueCallerConfig = {
  appKey: TRUECALLER_APP_KEY,
  appName: TRUECALLER_APP_NAME,
  lang: 'en',
  loginPrefix: 'getstarted',
  loginSuffix: 'verifymobile',
  ctaPrefix: 'use',
  ctaColor: '%23547aa8',
  ctaTextColor: '%23ffffff',
  btnShape: 'round',
  skipOption: 'manualdetails',
};
export const businessWebsiteUrl = 'https://www.business.fudr.in/';

export const mapMarkerIconUrl =
  'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png';

export const constants = {
  logoBasePath: '/static/media/',
  defaultCity: 'Jaipur',
  takeAwayModes: ['SP'],
  scanCode: {
    lookingToKnowAboutFudr: 'Looking to know more about Fudr?',
    visitOurBusinessSite: 'Visit our business site',
    scanCodeText: 'Scan QR code to order',
    enterCodeText: 'ENTER CODE',
    scanQRCode: 'SCAN CODE',
    selfPickup: 'SELF PICKUP',
    delivery: 'DELIVERY',
    titleManualCode: 'Please enter the restaurant code mentioned below QR code',
    enterCodeError: 'Incorrect restaurant code. Please check the code and retry.',
    invalidCode: 'Please enter valid restaurant code.',
    cameraAccessError: 'Seems like camera access is blocked',
    placeholderText: 'Enter Restaurant Code',
    undetectScanCode: 'Not able to scan code?',
    scanText: 'SCAN',
    nonFudrQRCodeText: 'Please Scan a Fudr QR code',
    deliveryOrders: 'Delivery orders',
    selfPickUpOrders: 'Self Pickup orders',
    ordersText: 'orders',
    outOfRestaurantScopeErrorTitle: 'Different QR Code',
    outOfRestaurantScopeErrorDescriptionPrefix: 'Seems like this QR code doesn’t belong to',
    outOfRestaurantScopeErrorDescriptionSuffix: `
    To scan this QR code:
    Use your mobile camera (iOS)
    Use Google Lens (Android)
    — or —
    Visit www.fudr.in
    `,
  },
  drawer: {
    loginCTA: 'Login',
    logoutCTA: 'LOGOUT',
    myOrders: 'My Orders',
    guestCashCardsLink: 'My Cards',
    menu: 'Menu',
    ordersRunning: 'Order(s) Running',
    aboutFudr: 'About FUDR',
    account: 'My Account',
    helpAndSupport: 'Help & Support',
    switch: 'Switch',
    aboutOutlet: 'About Outlet',
  },
  header: {
    switchCTA: 'SWITCH',
  },
  footer: {
    items: 'item(s)',
  },
  menu: {
    add: 'ADD',
    unavailable: 'Unavailable',
    select: 'SELECT',
    errorInfo: {
      INVALID_METHOD_PARAMS: {
        message: 'Incorrect restaurant code. Please check the code and retry.',
      },
      UPSELL_SESSION_TIMEOUT: {
        title: 'Time Out',
        message:
          'It seems you took too long to decide. Please tap on ‘Mystery Deals’ button to view deals',
        CTAText: 'OK, GOT IT',
      },
    },
    cashCardBanner: {
      titleSuffix: 'Card Balance',
    },
    orderAgainTitle: 'Order Again',
    previousOrderPopup: {
      orderDetailsText: 'Order Details',
      unavailableItemText: 'Item(s) Unavailable',
      unavailableItemDescription: 'A few of the items of that order are unavailable at the moment',
      addToCartAction: 'ADD TO CART',
      addedToCartText: 'Added to cart',
    },
    emptyMenu: {
      placeholderTitle: 'Menu Coming Soon',
      placeholderDescription: `We are in the process of digitizing our menu
      and it will be uploaded soon`,
    },
    readOnlyMenu: {
      menuItemCTA: 'VIEW',
      cartFooterLabel: 'Please call the waiter to place order',
    },
  },
  tableType: {
    table: 'Table',
  },
  account: {
    label: {
      name: 'Name',
      phoneNumber: 'Mobile no.',
      email: 'Email',
      vehicle: 'Vehicle',
    },
    editBtn: 'EDIT',
    general: 'General',
    vehicle: 'Vehicle',
    addVehicle: 'ADD VEHICLE',
    closeBtn: 'CLOSE',
  },
  aboutFudr: {
    title: 'About Fudr',
    iAccept: 'I accept',
    termsAndConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    contactUs: 'Contact Us',
    businessWebsiteText: 'business.fudr.in',
  },
  aboutOutlet: {
    title: 'About Outlet',
    phone: 'Phone',
    email: 'Email',
    address: 'Address',
    outletTimings: 'Outlet Timings',
    poweredBy: 'powered by',
    notProvidedText: 'Not available',
    forAnyQueriesText: 'For any queries, please contact us on the following:',
    closed: 'Closed',
  },
  foodItem: {
    free: 'Free',
    customizable: 'Customizable',
    customization: 'Customization',
  },
  cart: {
    title: 'Cart',
    viewCart: 'VIEW CART',
    addToCart: 'ADD TO CART',
    placeOrder: 'PLACE ORDER',
    proceed: 'PROCEED',
    yourOrder: 'Your Order',
    total: 'Total',
    includingTaxes: '(Including taxes)',
    includingTaxesAndCharges: '(Including taxes & charges)',
    specialInstructionPlaceholder: 'Special Instructions',
    takeAway: 'Take Away',
    deliveryCharges: 'Delivery Charges',
    orderPickupText: 'You need to self pick up from',
    minimumCartValue: 'Minimum cart value',
    looseDeal: 'You will lose this deal',
    orderConfirmation: {
      title: 'Almost there',
      description: 'Should we proceed with your order?',
      btnCTA: 'PLACE ORDER',
    },
    errorInfo: {
      UNKNOWN_SERVER_ERR: {
        message: 'Something went wrong while placing your order. Please try again.',
      },
      UPSELL_SESSION_TIMEOUT: {
        title: 'Time Out',
        message:
          'It seems you took too long to decide. Please tap on ‘Mystery Deals’ button to view deals',
        CTAText: 'OK, GOT IT',
      },
    },
  },
  guestDetails: 'Guest Details',
  confirmation: {
    viewOrder: 'VIEW ORDER',
    backToMenu: 'BACK TO MENU',
    specialInstructionText: 'Special Instruction',
    orderNumberText: 'Order no.',
    orderId: 'Order ID',
    viewCancelledItems: 'VIEW CANCELLED ITEMS',
    orderStatusText: {
      unAttended: 'Restaurant will shortly confirm your order',
      settled: 'Your order has been delivered. Enjoy your meal!',
      failed: 'Transaction of this order failed',
      cancelled: 'Sorry, your order is unavailable at this time',
      cancelledItemsText: 'Some items have been cancelled',
      delivery: {
        unAttended: 'Restaurant will shortly confirm your order and delivery time',
        unAttendedDescription: 'You’ll receive a SMS and email when order is confirmed',
        confirmedDescription: 'You’ll receive a SMS and email when order is out for delivery',
        ready: 'Our delivery boy is on the way',
        readyDescription: 'Show confirmation SMS or email at time of collection',
      },
      selfPickup: {
        unAttended: 'Restaurant will shortly confirm your order and pickup time',
        unAttendedDescription: 'You’ll receive a SMS and email when order is confirmed',
        confirmedDescription: 'You’ll receive a SMS and email when order is ready',
        ready: 'Your order is ready to be picked up.',
        readyDescription: 'Show confirmation SMS or email at time of collection',
      },
      dineIn: {
        settled: 'Your order has been served. Enjoy your meal!',
      },
      addOnDeliveryTime: 0, // in minutes
    },
    orderUpdates: {
      title: 'Order Updates',
      description: 'Please share your email address to receive your order updates.',
      enterEmailPlaceHolder: 'E-mail address',
      btnCTA: 'DONE',
    },
    enterEmail: {
      text: 'Want to be notified about your order?',
      link: 'Enter email address',
    },
    eta: 'ETA',
    etd: 'ETD',
    estimatedTimeOfArrival: 'Estimated Time of Arrival',
    estimatedTimeOfDelivery: 'Estimated Time of Delivery',
    deliveryAddress: 'Delivery Address',
    paymentDetails: {
      title: 'Payment Details',
      paymentMode: {
        label: 'Payment Mode',
        cashCard: 'Cash Card',
        prepaid: 'Online',
        postpaid: 'Cash',
      },
      paymentStatus: {
        label: 'Payment Status',
        pending: 'Pending',
        successful: 'Successful',
        remark: 'Remark',
        userDropped: 'User Dropped',
        invalidTxn: 'Invalid Transaction',
      },
      amountLabel: 'Amount',
      transactionDateLabel: 'Transaction on',
      pointsDebitedLabel: 'Points debited',
      referenceIdLabel: 'Reference ID',
    },
    refundDetails: {
      title: 'Refund Details',
      refundMode: {
        label: 'Refund Mode',
        cashCard: 'Cash Card',
        prepaid: 'Source account from which the payment was made',
      },
      refundStatus: {
        label: 'Refund Status',
        processed: 'Processed',
        initiated: 'Initiated',
        failed: 'Failed',
        remark: 'Remark',
      },
      amountLabel: 'Amount',
      initiateDateLabel: 'Initiated on',
      completeDateLabel: 'Processed on',
      pointsCreditedLabel: 'Points credited',
      referenceIdLabel: 'Reference ID',
      arnLabel: 'ARN (Acquirer Reference Number)',
    },

    backToMenuConfirm: 'Navigate to menu?',
    accessPageLoginText: 'To access this page please login',
    errorInfo: {
      UNAUTHORIZED_ACTION: {
        title: 'Access Denied',
        message:
          'It seems you do not have the necessary permission to access the resource. Please login again',
        CTAText: 'LOGIN',
      },
      OTP_INVALID: {
        title: 'OTP not verified',
        message: 'It seems like you’ve entered a wrong otp.',
        CTAText: 'BACK',
      },
    },
  },
  orders: {
    title: 'My Orders',
    orderDetails: 'Order Details',
    noOrder: 'No Orders to Display',
    backToMenu: 'Back to Menu',
    viewMoreOrders: 'View More Orders',
    sortType: 'orderPunchTime,DESC',
    pageSize: 10,
    orderStatus: {
      pending: 'Pending',
      confirmed: 'Confirmed',
      delivered: 'Delivered',
      failed: 'Failed',
      cancelled: 'Cancelled',
      ready: 'Ready',
      outForDelivery: 'Out for Delivery',
    },
  },
  taxes: {
    inclusive: 'tax inclusive',
    exclusive: '+ taxes',
  },
  guestScreen: {
    skipButton: 'Skip & Place Order',
    placeOrder: 'Place Order',
  },
  payment: {
    paytmUrl: paytmUrl,
    cashfreeUrl: cashfreeUrl,
    paymentCTA: 'PAY NOW',
    orderNow: 'ORDER NOW',
    processingPayment: {
      title: 'Processing Payment...',
      description: 'Please do not Refresh page or press the Back button',
    },
    paymentFailed: 'Payment Failed',
    paymentFailedDescription:
      'We are unable to process your payment. If any money was deducted it will be refunded in 2-3 business days',
    backToCartCTA: 'Back To Cart',
    paymentSuccess: 'Payment Successful',
    paymentType: {
      title: 'Place Order',
      payLater: 'Pay by Cash',
      payNow: 'Pay Online',
      useCard: 'Use {outletCashCardTitle} Card Balance',
      swipeToOrderCTAText: 'SWIPE TO PLACE ORDER',
      swipeToPayCTAText: 'SWIPE TO PAY',
      swipeToRechargeAndOrder: 'RECHARGE AND ORDER',
      remainderPrefix: 'remaining',
      errorMessage: {
        invalidAmount: 'Please enter a valid amount',
        amountWithoutDecimals: 'Please enter amount without decimal point',
        minRechargeAmountNotMetPrefix: 'Min. recharge amount is',
        maxRechargeAmountExceededPrefix: 'Max. recharge amount is',
      },
    },
    errorInfo: {
      UNKNOWN_SERVER_ERR: {
        message: 'Something went wrong while placing your order. Please try again.',
      },
    },
    backToCartCTAText: 'BACK TO CART',
    backCTAText: 'BACK',
    paymentVerificationFailedRemark:
      'Payment verification failed. If the amount has been deducted, it will be returned to your bank account.',
  },
  authentication: {
    loginTitle: 'Log In',
    loginDescription: 'Please enter your registered phone number to Log In',
    loginText: 'LOG IN',
    firstNameDescription: 'What should we call you',
    phoneNumberDescription: 'OTP will be sent on this number to verify',
    emailDescription: 'Your bill will be mailed to you',
    otpTitle: 'Verify Number',
    otpDescription: 'Please enter OTP send on',
    changeNumberText: 'Change',
    sendOtpAgainText: 'Resend OTP',
    verifyOTPLaterText: 'Verify Later',
    proceedBtnText: 'PROCEED',
    sendOtpText: 'SEND OTP',
    otpSentText: 'OTP Sent',
    resendOTPErrorText: 'Seems we are facing some issue. Please retry after some time',
    incorrectOTPEntered: 'The OTP you have entered is incorrect',
    placeHolder: {
      name: 'Name',
      phoneNumber: 'Phone number',
      email: 'E-mail (Optional)',
      otp: 'Enter OTP',
    },
    toastMessage: {
      successfulLogin: 'Successfully logged in',
    },
    errorInfo: {
      USER_REJECTED: {
        title: 'Something went wrong',
        message: 'We encountered a problem while trying to login through truecaller',
      },
      TC_TIMEOUT_ERR: {
        title: 'Something went wrong',
        message: 'We encountered a problem while trying to login through truecaller',
      },
      TC_REQ_ERR: {
        title: 'Something went wrong',
        message: 'We encountered a problem while trying to login through truecaller',
      },
      UNKNOWN_SERVER_ERR: {
        title: 'Something went wrong',
        message: 'We encountered a problem while trying to login through truecaller',
      },
    },
    tryAgainCTA: 'TRY AGAIN', // TODO: Move this to globals.js
    userRejected: 'USER_REJECTED',
    requestIdNotFound: 'NOT_FOUND',
    trueCallerTimeout: 'TC_TIMEOUT_ERR',
    trueCallerRequestError: 'TC_REQ_ERR',
    screen: {
      title: 'You need to login',
      description: 'The information you are trying to access requires you to login',
      loginCTAText: 'LOGIN',
    },
  },
  orderConfirm: {
    orderUnattendedStatuses: ['UNATTENDED', 'DRAFT'],
  },
  welcome: {
    dineInCode: 'DI',
    driveThroughCode: 'DT',
    switchText: 'SWITCH',
    backBtnText: 'BACK',
    switchTable: 'Switch Table',
    errorInfo: {
      INVALID_METHOD_PARAMS: {
        message: 'Incorrect restaurant code. Please check the code and retry.',
      },
    },
  },
  search: {
    placeHolder: {
      menu: 'Search for item',
      selfPickup: 'Search restaurants',
      selectCity: 'Search City',
    },
    noSearchResultText:
      'No results match with the search criteria. Please clear the search to view the menu.',
  },
  betaErrorMessages: {
    emoji: '😕',
    title: 'Oops! It seems you are not part of the fudr beta group',
    description: `Orders currently open only for limited users.
    To join the group please contact the restaurant counter or you may email us at admin@fudr.in`,
    buttonText: 'Back To Menu',
  },
  customizationMenu: {
    repeatedCustomizationText: 'Repeat last customization?',
    addNewBtn: 'ADD NEW',
    repeatLastBtn: 'REPEAT LAST',
    noCustomizationsAvailable: 'Customizations not available at the moment',
    basePrice: 'Base Price',
    errorCTA: 'TRY AGAIN',
    unableToLoadCustomization: 'Unable to load customization',
  },
  defaultCountryCode: '91',
  updateProfile: {
    title: 'Update Profile',
    description: 'Please update your profile for a better experience',
    btnCTA: 'PROCEED',
  },
  cancelledItems: {
    title: 'Cancelled Items',
  },
  unattendedTimer: 180000,
  tncError: 'Please accept terms & conditions to proceed',
  vehicleDetails: {
    title: 'Vehicle Details',
    description: 'Your vehicle need to be near the restaurant',
    btnCTA: {
      proceedToOrderCTA: 'PROCEED TO ORDER',
      addVehicleCTA: 'ADD VEHICLE',
    },
    placeHolder: {
      vehicleNumber: {
        name: 'Vehicle no. (RJ14-CE-0000)',
        description: 'Complete vehicle no. i.e. (RJ14-CE-0000)',
      },
      identification: {
        name: 'Vehicle Description (Optional)',
        description: 'Eg. White Honda City',
      },
    },
    error: 'Please provide a vehicle number',
    addNewVehicle: 'ADD NEW VEHICLE',
    viewSavedVehicles: 'VIEW SAVED VEHICLES',
    validations: {
      vehicleNumberMaxLength: 13,
      vehicleIdentificationMaxLength: 56,
    },
  },
  offers: {
    title: 'Offers',
    availableText: 'available',
    discountText: 'You saved',
    loginCTA: 'LOGIN',
    applyCouponCTA: 'APPLY',
    viewOfferDetails: 'view offer details',
    offerDetailsText: 'Offer Details',
    offerAvailableFor: 'Offer available for',
    applyOfferCTA: 'APPLY OFFER',
    orderText: 'Order',
    buy: 'Buy',
    get: 'Get',
    free: 'Free',
    and: 'and',
    item: 'item',
    offOnMinBillOf: 'off on minimum bill of',
    offOnTheBill: 'off on the bill',
    offOnPurchaseOff: 'off on purchase of',
    maxDiscountUpto: 'Maximum discount upto',
    applicableOnlyOn: 'Applicable only on',
    offOnThe: 'off on the',
    offerValidFrom: 'Offer valid from',
    offerAvailableOn: 'Offer available on',
    sameItemLabel: 'Selected & Discounted item should be same',
    sameCategoryLabel: 'Selected & Discounted item should belong to the same category',
    multiCategoriesLabel: 'Selected & Discounted item can be among eligible categories',
    multiItemsLabel: 'Selected & Discounted item can be among eligible items',
    applicableOnSelectedCategories: 'Applicable on Selected Categories',
    discountUpto: 'Discount upto',
    off: 'OFF',
    selectAnyText: 'Select any',
    horizontal: {
      off: 'OFF',
      free: 'FREE',
      onMinBillOf: 'On minimum bill of',
      onTotalBill: 'On  the total bill',
      onTheBill: 'On the bill',
      onPurchaseOff: 'On purchase of the',
      conditionsApply: 'conditions apply',
      offerApplicableText: 'Offer can be availed on the cart',
      contactRestaurantText: 'Contact Restaurant to avail this offer',
    },
    toastMessage: {
      categoryMaxSelectionReached: 'Max. selection limit reached for this category',
      maxSelectionReached: 'Max. selection limit reached',
    },
    exclusiveGuestOffers: {
      loggedOutGuestOfferCardText: 'We may have offers just for you',
      loggedInGuestOfferText: 'JUST FOR YOU',
      noOffersToastMessage: 'Sorry, no offers running at the moment',
    },
  },
  multipleCustomizations: {
    title: 'Multiple Customizations',
    description: 'This item has multiple customizations added. Proceed to cart to remove item?',
    handleCTA: 'GO TO CART',
  },
  services: {
    title: 'How may I help you',
    btnCTA: 'SEND REQUEST',
    toastMessage: 'Restaurant will shortly attend your request',
    toastMessageTime: 3000,
    requestedAssistanceTimeInSeconds: 60,
    requestedAssistanceText: 'Requested short while ago, we are on it',
  },
  selfPickup: {
    title: 'Self Pickup',
    introductionTitle: 'Self Pickup',
    knowMore: 'KNOW MORE',
    placeOrder: 'Place Order',
    reachRestaurant: 'Reach Restaurant',
    pickOrder: 'Pick your Order',
    popUpDetails: {
      description: 'Now you can order food online and pick up from the restaurant.',
      tnc: [
        '100% payment should be done at the time of placing order.',
        'Show the confirmation SMS to the restaurant to collect your order.',
        'Order cannot be cancelled once placed.',
        'Please ensure the address of restaurant while placing order. You’ll need to pickup from the same address.',
      ],
    },
    restaurantList: {
      noRestaurantAvailable: 'No restaurant available.',
      restaurantUnavailable: 'Restaurant not accepting',
      changeLocation: 'CHANGE LOCATION',
    },
    description1:
      'Coming soon to your city. Meanwhile, please tell us the restaurant you want to see on FUDR.',
    description2: 'Not able to find your Restaurant?',
    description3: 'and we’ll try to get them onboard.',
    tellUsText: 'Tell us',
  },
  pickupTime: {
    title: 'Pickup Time',
    description: 'Tell us about your estimated arrival time. This helps us to serve you better.',
    proceedBtnText: 'PROCEED',
    minuteSuffix: 'min',
    atRestaurantLabel: 'At restaurant',
  },
  updateProfileFields: {
    name: {
      title: 'Update Name',
      nameFieldDescription: 'What should we call you',
      btnCTA: 'UPDATE',
    },
    email: {
      title: 'Update E-mail',
      emailDescription: 'Order updates will be shared on this email address',
      btnCTA: 'UPDATE',
      ADD: {
        title: 'Add email address',
        description:
          'Please share your email address to receive your cash card transaction details.',
        btnCTA: 'PROCEED',
      },
      VERIFY: {
        title: 'Confirm email address',
        description:
          'Please confirm your email address to receive your cash card transaction details.',
        btnCTA: 'CONFIRM & PROCEED',
      },
    },
    vehicle: {
      title: 'Update Vehicle Details',
      placeHolder: {
        vehicleNumber: {
          name: 'Vehicle no. (RJ14-CE-0000)',
          description: 'Please provide your complete vehicle number',
        },
        identification: {
          name: 'Vehicle Description (Optional)',
          description: 'Eg. White Honda City',
        },
      },
      CTABtn: {
        removeCTA: 'REMOVE',
        updateCTA: 'UPDATE',
      },
      removeVehicle: {
        title: 'Remove Vehicle',
        description: 'Are you sure you want to remove this vehicle',
        btnCTA: 'REMOVE',
      },
    },
    error: {
      name: 'Looks like you’ve not entered your name. Please provide your name',
      email: 'Please provide a valid email address',
      phoneNumber: 'Please provide valid phone number',
    },
    validations: {
      nameMaxLength: 56,
    },
  },
  selectCity: {
    title: 'Select City',
    unableToLocateCityText: 'Not able to find your city?',
    tellUsText: 'Tell us',
    unavailableCity: 'No city available',
  },
  tellUs: {
    title: 'Tell Us',
    placeHolder: {
      yourCity: 'Your City',
      state: 'State',
      restaurantName: 'Restaurant you want to see on FUDR',
    },
    sendCTA: 'SEND',
    validations: {
      cityMaxLength: 32,
      stateMaxLength: 16,
      restaurantName: 64,
    },
    writeUsAt: 'Write us at',
    description1: 'Tell us the restaurant you want to see on FUDR.',
    description2: 'and we’ll try to get them onboard.',
  },
  helpAndSupport: {
    description: 'Please contact us for any questions or comments regarding this Platform.',
    phone: 'Phone: +91 9352665488',
    email: 'Email:',
    time: 'Time: Mon - Sat (9:00 - 18:00)',
    mail: 'admin@fudr.in',
  },
  deliveryAddress: {
    title: 'Delivery Address',
    addressDetails: 'Address Details',
    checkDeliverabilityTitle: 'Check Deliverability',
    deliveryLocationTitle: 'Delivery Location',
    selfPickupFrom: 'Self Pickup from',
    placeHolder: {
      locality: 'Locality',
      addressLine1: 'Address Line 1',
      landmark: 'Landmark / How to reach (optional)',
      title: "Eg. Caesar's Palace",
      titleNew: "Eg. Rahul's Place",
      addressLine: 'House / Flat / Floor no.',
    },
    addNewAddress: 'ADD NEW ADDRESS',
    addAddress: 'ADD ADDRESS',
    updateAddress: 'UPDATE ADDRESS',
    confirmlocation: 'CONFIRM LOCATION',
    viewSavedAddress: 'VIEW SAVED ADDRESS',
    setLocationOnMapCTA: 'Set location on map',
    areaNotDeliverable: 'Sorry, we’re not delivering in this area.',
    localityInvalid: 'Please select a locality from suggested options',
    orText: 'OR',
    saveAs: 'Save as',
    undeliverableText: 'Undeliverable',
    notdeliverableText: 'Sorry, we’re not delivering at this location',
    errorMessage: 'Please enter a valid input!',
    validations: {
      addressMaxLength: 100,
      landmarkMaxLength: 100,
    },
  },
  card: {
    deliveryCard: {
      deliveryInSelectedAreas: 'We deliver in selected areas in',
      check: 'Check',
      provideYourAddress: 'Provide your address',
      checkDeliverability: 'to check deliverability',
      deliverabilityForYourLocality: 'deliverability for your locality',
      deliverTo: 'Deliver to',
      edit: 'Edit',
      selfPickUpFrom: 'Self Pickup from',
      getItDelivered: 'Get it delivered',
    },
    offeredItemsCard: {
      title: 'OFFERED ITEMS',
    },
    availedUpsellItemsCard: {
      title: 'MYSTERY DEALS',
    },
    previousOrderCard: {
      addToCartActionText: 'ADD TO CART',
      addedToCartText: 'Added to cart',
    },
  },
  orderMode: {
    title: 'Order Mode',
    serviceUnavailable: 'This mode is not available at the moment',
    scanQRCode: 'Scan QR code',
  },
  verifyCart: {
    title: 'Items in Cart',
    description: 'You’ll lose items in your cart if you switch the outlet.',
    cta: {
      viewCartCTA: 'VIEW CART',
      switchRestaurantCTA: 'SWITCH RESTAURANT',
    },
  },
  contactInfo: {
    title: 'Contact',
  },
  menuItemDetail: {
    btnCTA: 'BACK TO MENU',
    carouselSettings: {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  restaurantOperatingModeMessages: {
    READ: 'We are not accepting online orders at the moment',
    DEACTIVE: 'We are not accepting online orders at the moment',
    CLOSED: 'We are closed for the day',
    NOTOPEN: 'We are not yet open for the day',
    notAcceptingText: 'We are not accepting',
    ordersText: 'orders at the moment',
  },
  progressiveWebApp: {
    install: {
      installSuggestText: 'Instant download Mini app',
      appText: 'APP',
      appetizerSuffixText: 'etizer',
      description: `Get {restaurantEntityName} web-app for a quick and personalised experience.`,
      getAppetizerTitlePrefix: 'Get ',
      openAppText: 'OPEN APP',
      preparingAppText: 'PREPARING APPetizer...',
    },
  },
  moreItems: {
    title: 'Select more items',
    description: 'You can avail the following items additionally in this offer',
    btnCTA: 'CONTINUE ANYWAY',
    itemText: 'item(s)',
    fromText: 'from',
    categoryText: 'category',
  },
  upsell: {
    itemCard: {
      discountPrefixText: 'You save ',
      passDealCTAText: 'PASS THIS DEAL',
      addToCartCTAText: 'ADD TO CART',
      previouslySelectedDeal: 'Previously selected deal',
      passDealSupportingLabel: `If you pass, you won't get this deal again`,
    },
    loginTitle: 'Log In to view Mystery Deals',
    removeUpsellPopup: {
      title: 'Remove Item ?',
      description: 'You will not be able to avail this item at the same discounted price',
      popupCTA: 'REMOVE',
    },
    dealAlreadyTakenPopup: {
      title: 'Deal already taken',
      description: 'You can take only one deal per order. Get more deals on your next order',
      popupCTA: 'OK, GOT IT',
    },
    addMoreItemsPopup: {
      title: 'Add more items',
      description: 'You’ll need to add more items in your cart to get Mystery Deals.',
      popupCTA: 'OK, GOT IT',
    },
    noDealsLeftPopup: {
      title: 'No Deals left',
      description:
        'You’ve already passed all the deals we had for you. Get more deals on your next order.',
      popupCTA: 'OK, GOT IT',
    },
    dealUnavailablePopup: {
      title: 'No Deals available',
      description: 'Currently, no deals are available. Please check back on your next order.',
      popupCTA: 'OK, GOT IT',
    },
    removeItemAndLooseDealPopup: {
      title: 'Remove item & Lose Deal?',
      description:
        'If you remove this item your cart value will not be enough for the following Mystery Deal.',
      popupCTA: 'KEEP IT',
      secondaryCTA: 'REMOVE IT',
    },
    cartNotEnoughForDealPopup: {
      title: 'Cart not enough for Deal',
      description: 'Your cart value is not enough for the following Mystery Deal.',
      popupCTA: 'ADD MORE ITEMS',
      secondaryCTA: 'LOSE DEAL',
    },
  },
  cashCard: {
    cashCardPreview: {
      cardNameSuffix: 'Card',
      balanceLabel: 'Balance',
      knowMoreText: 'KNOW MORE',
      rechargeText: 'RECHARGE',
      catchyLabel: 'Simplified Payments',
      callToAction: 'Add points in your card and complete payment in a jiffy',
    },
    popup: {
      info: {
        titleSuffix: 'Card',
        rechargeText: 'RECHARGE',
        loginToRechargeText: 'LOGIN TO RECHARGE',
      },
    },
    guestCashCardsList: {
      title: 'My Cards',
      noCashCard: 'No cash cards to display',
      backToMenuLink: 'Back to Menu',
      viewMore: 'View More',
      errorInfo: {
        INVALID_METHOD_PARAMS: {
          title: 'Something went wrong',
          message: `It seems that there was an issue fetching the cash card details. Please try again.`,
          CTAText: 'TRY AGAIN',
        },
        UNKNOWN_SERVER_ERR: {
          title: 'Something went wrong',
          message: `The application has encountered an unknown error. It doesn't appear to have affected your data, but our team have been automatically notified and will be looking into this with the utmost urgency.`,
          CTAText: 'BACK',
        },
      },
    },
    guestCashCardTransactions: {
      title: 'Card Detail',
      noTransactions: 'No transactions to display',
      viewMore: 'View More',
      cashCardPausedInstruction: `This Cash card has been stopped by the restaurant. You can use the balance points but won't be able to recharge it.`,
      refundPendingLabel: 'Refund pending',
      refundFailedLabel: 'Refund failed',
      refundSuccessLabel: 'Refund',
      paymentPendingLabel: 'Pending',
      paymentFailedLabel: 'Failed',
    },
    rechargeScreen: {
      rechargeOngoing: {
        title: 'Recharge',
        errorMessage: {
          invalidAmount: 'Please enter a valid amount',
          amountWithoutDecimals: 'Please enter amount without decimal point',
          minRechargeAmountNotMetPrefix: 'Min. recharge amount is',
          maxRechargeAmountExceededPrefix: 'Max. recharge amount is',
        },
        rechargeCTAText: 'PROCEED',
      },
      rechargeConfirmation: {
        title: 'Transaction Details',
        addedTo: 'added to',
        refundedTo: 'refunded to',
        cardName: '{outletCashCardTitle} card',
        transactionIdLabel: 'Ref. ID:',
        subscriptionEmailSuffix: 'Transaction receipt sent to',
        doneCTAText: 'DONE',
        refundPendingLabel: 'Refund Pending',
        refundFailedLabel: 'Refund Failed',
        rechargePendingLabel: 'Recharge Pending',
        rechargeFailedLabel: 'Recharge Failed',
        transactionPendingInstruction: 'It may take upto 24 hrs to complete the process',
        rechargeFailedInstruction: 'Any deducted amount will be credited within 7 working days',
        refundFailedInstruction:
          'It seems there was some issue in this transaction. For more information, please write us at',
        processingOrderLabel: 'Processing your Order',
      },
    },
    rechargePane: {
      rechargeCardTitle: 'Recharge {outletCashCardTitle} Card',
      balanceSubTitle: 'Balance:',
      rechargeAmountInputPlaceholder: 'Amount',
      rechargePointsValueIndicator: `You'll get`,
    },
  },
  feedback: {
    feedbackGuestPopup: {
      noRewardFormContent: {
        title: 'Please tell us about yourself',
        description: 'This will help us to serve you better on your next visit',
        popupCTA: 'PROCEED',
      },
      rewardFormContent: {
        title: 'Who should we give discount to',
        description: 'Share your details to get your coupon code',
        popupCTA: 'GET COUPON CODE',
      },
      noRewardGuestInfoContent: {
        title: 'Submit Feedback',
        popupCTA: 'PROCEED',
      },
      rewardGuestInfoContent: {
        title: 'Discount Coupon',
        popupCTA: 'GET COUPON CODE',
      },
    },
    errorInfo: {
      OTP_INVALID: {
        title: 'OTP not verified',
        message: 'It seems like you’ve entered a wrong otp.',
      },
    },
  },
  globalErrorInfo: {
    UNKNOWN_SERVER_ERR: {
      message: 'Something went wrong',
    },
    CONTEXTLESS_URL_HIT: {
      title: 'You Seem To Be Lost',
      message: `Sorry, we cannot find what you are looking for. There is a lot to explore on the home page though.`,
      CTAText: 'GO TO HOME',
    },
    DEFAULT: {
      title: 'Something went wrong',
      message: `The application has encountered an unknown error. It doesn't appear to have affected your data, but our team have been automatically notified and will be looking into this with the utmost urgency.`,
      CTAText: 'BACK',
    },
    internetDisconnectivityErrorMessage: 'No internet. Please check your connectivity.',
  },
  popularLabel: 'POPULAR',
  desktopLandingScreen: {
    title: {
      lookingToKnowAboutFudr: 'Looking to know about Fudr',
      lookingToOrderFood: 'Looking to Order food',
    },
    CTA: {
      visitBusinessSite: 'Visit Business site',
      visitOrderingSite: 'Visit Ordering site',
    },
  },
  orderDetails: {
    title: 'Order Details',
    discount: 'Discount',
    taxes: 'Taxes',
    total: 'Total',
    orderNumber: 'Order no.',
  },
  additionalCharges: {
    title: 'Additional Charges',
    taxes: 'Taxes',
    deliveryCharges: 'Total Delivery Charges',
    deliveryDiscount: 'Delivery Discount',
  },
  googleMap: {
    userLocationPermissionError: ' Location denied, please provide location permission',
  },
  deals: {
    howThisWorks: 'How this works',
    noBrandDealDescription: 'Seems like there are no coupons at the moment',
    getCouponCTA: 'GET COUPON',
    viewMoreCouponCTA: 'VIEW MORE COUPON',
    useAnotherNumberCTA: 'Use another number',
    errorInfo: {
      DEAL_ALREADY_ASSIGNED_REDEEMED: {
        title: 'Coupon already redeemed',
        message:
          'It seems like you’ve already redeemed the coupon. We’ll notify you new coupons are available.',
      },
      DEAL_ALREADY_ASSIGNED_NOT_REDEEMED: {
        title: 'Coupon already taken',
        message:
          'It seems like you’ve already taken this coupon. Please visit restaurant to redeem your coupon.',
      },
      OTP_INVALID_NOT_REDEEMED: {
        title: 'OTP not verified',
        message: 'It seems like you’ve entered a wrong otp.',
      },
    },
  },
  dealDetail: {
    CTA: {
      getCouponCTA: 'GET COUPON',
    },
    shareSection: {
      title: 'Spread Happiness !!!',
      description: 'Share this coupon with your Friends and let them too enjoy the discount',
    },
    errorInfo: {
      NOT_FOUND: {
        title: 'Coupon not available',
        message: 'It seems the coupon you are looking is not available.',
        CTAText: 'VIEW OTHER COUPONS',
      },
      DEAL_EXPIRED: {
        title: 'Coupon expired',
        message: 'It seems the coupon you are looking is expired.',
        CTAText: 'VIEW OTHER COUPONS',
      },
      DEAL_MAX_ALLOTED: {
        title: 'Coupon not available',
        message: 'It seems the coupon you are looking is not available.',
        CTAText: 'VIEW OTHER COUPONS',
      },
      OTP_INVALID: {
        title: 'OTP not verified',
        message: 'It seems like you’ve entered a wrong otp.',
      },
    },
  },
  couponDetail: {
    couponSharedText: 'You coupon code has been shared on',
    redeemDealSection: {
      title: 'When are you planning to redeem this coupon',
      description: 'We’ll make sure you don’t miss your coupon',
    },
    CTA: {
      share: 'TELL YOUR FRIENDS',
      viewMoreCoupon: 'VIEW MORE COUPON',
    },
    downloadCouponCTA: 'SAVE COUPON CODE',
    errorInfo: {
      NOT_FOUND: {
        title: 'Coupon not available',
        message: 'It seems the coupon you are looking is not available.',
        CTAText: 'VIEW OTHER COUPONS',
      },
      DEAL_EXPIRED: {
        title: 'Coupon expired',
        message: 'It seems the coupon you are looking is expired.',
        CTAText: 'VIEW OTHER COUPONS',
      },
      DEAL_MAX_ALLOTED: {
        title: 'Coupon not available',
        message: 'It seems the coupon you are looking is not available.',
        CTAText: 'VIEW OTHER COUPONS',
      },
    },
    couponStatus: {
      redeemed: 'Redeemed',
      expired: 'Expired',
      subscribed: 'Subscribed',
    },
  },
  couponAlreadyRedeemed: {
    title: {
      redeemed: 'Coupon already Redeemed',
      notRedeemed: 'Coupon for first time customer',
      justForYou: 'Coupons Just for You',
    },
    description: {
      redeemed: 'Seems like you’ve already redeemed the coupon',
      notRedeemed:
        'This coupon is available only for new customers and it seems like you are already our customer. Don’t worry, we ‘ve a coupon for you',
    },
    moreDeals: 'Coupon you might be interested in',
    noDealsText:
      'Seems no deal is available at the moment. Come back later for more exciting deals.',
  },
  couponException: {
    title: {
      newCustomers: 'Coupon for first time customer',
      allCustomers: 'Coupon already Taken',
      alreadyRedeemed: 'Coupon already Redeemed',
    },
    description: {
      newCustomers:
        'Hi {guestName},{br} This coupon is available only for new customers and it seems like you are already our customer. Don’t worry, we ‘ve a coupon for you',
      allCustomers:
        'Hi {guestName},{br} Seems like you’ve already taken this coupon. Please visit restaurant to redeem your coupon',
      alreadyRedeemed:
        'Hi {guestName},{br} You’ve already redeemed the coupon on <b>{redeemedTime}</b> and got discount of <b>{discountAmount}</b>. We’ve a coupon you might be interested in.',
      alreadyRedeemedItemFree:
        'Hi {guestName},{br} You’ve already redeemed the coupon on <b>{redeemedTime}</b> and availed free item. We’ve a coupon you might be interested in.',
      alreadyRedeemedCouponStreak:
        'Hi {guestName},{br} You’ve already redeemed the coupon streak on <b>{redeemedTime}</b>. We’ve a coupon you might be interested in.',
      claimedMultipleOffers:
        'You’ve already redeemed this offer multiple times, please go to More > History to view details',
    },
    CTA: {
      newCustomers: 'VIEW MORE COUPON',
      allCustomers: 'VIEW COUPON CODE',
      viewMoreCoupon: 'VIEW MORE COUPON',
    },
  },
  share: {
    title: 'Spread Happiness !!!',
    description: 'Share this coupon with your Friends and let them too enjoy the offer',
  },
  greetingSplashScreen: {
    welcomePrefix: 'Welcome to',
    welcomeBackPrefix: 'Glad to have you back at',
  },
  membership: {
    popup: {
      title: 'Member ID',
      description: 'Please enter your Club Member ID for billing purpose',
      memberIdFieldPlaceholder: 'Enter Member ID',
      submitButton: 'PROCEED',
      memberIdFieldError: 'Please enter your Member ID',
    },
  },
  fallbackUI: {
    NonExistentSubdomainFallback: {
      title: 'Unable to reach this page',
      description: `We are unable to reach
        the web address you are looking for`,
      atnBtn: 'GO HOME',
    },
  },
  overlayErrorComponent: {
    viewDetails: 'View Details',
    hideDetails: 'Hide Details',
  },
};
