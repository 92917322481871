import React, { Component } from 'react';

import './DefaultPage.scss';
import * as FudrLoadingAnimationData from '../../assets/animations/fudr-loader-light-bg.json';

import Animatable from '../../components/elements/Animatable';
class DefaultPage extends Component {
  render() {
    return (
      <div className="default-page">
        <div className="fudr-loading-animation">
          {/* <Animatable animationData={FudrLoadingAnimationData} /> */}
        </div>
      </div>
    );
  }
}

export default DefaultPage;
