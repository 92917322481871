// used for retrieving and setting data in web and framework through a consistent api surface
export const getItem = (storageType, key) => {
  switch (storageType) {
    //get data from local storage
    case 'localstorage':
      return JSON.parse(localStorage.getItem(key));
    //get data from session storage
    case 'sessionstorage':
      return JSON.parse(sessionStorage.getItem(key));
    default:
      return;
  }
};

export const setItem = (storageType, key, value) => {
  switch (storageType) {
    //set data in local storage
    case 'localstorage':
      localStorage.setItem(key, JSON.stringify(value));
      break;
    //set data in session storage
    case 'sessionstorage':
      sessionStorage.setItem(key, JSON.stringify(value));
      break;
    default:
      return;
  }
};

export const removeItem = (storageType, key) => {
  switch (storageType) {
    // remove data from local storge
    case 'localstorage':
      localStorage.removeItem(key);
      break;
    // remove data from session storage
    case 'sessionstorage':
      sessionStorage.removeItem(key);
      break;
    default:
      break;
  }
};
