import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import resourcesConfig from '../../../resources';
import { foodItemType } from '../config';

export const outletOperatingMode = {
  READ: 'READ',
  DEACTIVE: 'DEACTIVE',
  STOPPED: 'STOPPED',
  CLOSED: 'CLOSED',
  NOTOPEN: 'NOT_OPEN',
  ACTIVE: 'ACTIVE',
};

export const getOutletOperatingMode = ({ outletMode, serviceTypeStatus }) => {
  let mode = '';
  if (
    isEqual(outletMode, outletOperatingMode.READ) ||
    isEqual(outletMode, outletOperatingMode.DEACTIVE)
  ) {
    mode = outletMode;
  } else {
    switch (serviceTypeStatus) {
      case outletOperatingMode.CLOSED:
        mode = outletOperatingMode.CLOSED;
        break;
      case outletOperatingMode.NOTOPEN:
        mode = outletOperatingMode.NOTOPEN;
        break;
      case outletOperatingMode.STOPPED:
        mode = outletOperatingMode.STOPPED;
        break;
      default:
        break;
    }
  }
  return mode;
};

export const renderOutletOperatingModeMessage = ({ mode, serviceType }) =>
  ({
    [outletOperatingMode.READ]: (
      <FormattedMessage id="outletReadMode" defaultMessage={resourcesConfig.outletReadMode} />
    ),
    [outletOperatingMode.DEACTIVE]: (
      <FormattedMessage
        id="outletDeactiveMode"
        defaultMessage={resourcesConfig.outletDeactiveMode}
      />
    ),
    [outletOperatingMode.CLOSED]: (
      <FormattedMessage id="outletClosedMode" defaultMessage={resourcesConfig.outletClosedMode} />
    ),
    [outletOperatingMode.NOTOPEN]: (
      <FormattedMessage id="outletNotOpenMode" defaultMessage={resourcesConfig.outletNotOpenMode} />
    ),
    [outletOperatingMode.STOPPED]: (
      <FormattedMessage
        id="notAcceptionOrdersText"
        defaultMessage={resourcesConfig.notAcceptionOrdersText}
        values={{
          serviceType,
        }}
      />
    ),
  }[mode]);

export const renderFoodTypeName = (type) => {
  switch (type) {
    case foodItemType.veg.name:
      return type;
    case foodItemType.nonVeg.name:
      return type;
    case foodItemType.egg.name:
      return foodItemType.nonVeg.name;
    default:
      return null;
  }
};

export const authenticationResolutionConfig = {
  basicAuthentication: 'BASIC_AUTHENTICATION',
  basicLoyaltyAuthentication: 'BASIC_LOYALTY_AUTHENTICATION',
};

export const appDownloadNudgesConfig = {
  menuBanner: 'MENU_BANNER',
  confirmation: 'CONFIRMATION',
  profile: 'PROFILE',
};
