const routes = {
  containers: {
    scan: 'scan',
    restaurant: 'restaurant',
    cart: 'cart',
    confirmation: 'confirmation',
    payment: 'payment',
    orders: 'orders',
    account: 'account',
    welcome: 'welcome',
    offers: 'offers',
    custom: 'c',
    aboutFudr: 'about-fudr',
    aboutOutlet: 'about-outlet',
    termsAndConditions: 'terms-and-conditions',
    privacyPolicy: 'privacy-policy',
    helpAndSupport: 'help-and-support',
    cashCards: 'cash-cards',
    getApp: 'getapp',
    deals: 'deals',
    coupon: 'coupon',
    deal: 'deal',
    feedback: 'feedback',
    rewardsClubSignup: 'rewards-club',
  },
  offers: {
    offerList: 'list',
    availOffer: 'avail-offer',
  },
  feedback: {
    form: 'form',
    success: 'success',
  },
};

export default routes;
