import React, { Component } from 'react';
import { BeatLoader } from 'halogenium';
import './Spinner.scss';

export default class Spinner extends Component {
  render() {
    return (
      <>
        <div className="SpinnerOverlay__div"></div>
        <BeatLoader className="Spinner" color="#ffffff" size="25px" />
      </>
    );
  }
}
