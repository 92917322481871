export const dateFormats = {
  ISO: 'YYYY-MM-DD',
  STANDARD: 'DD MMM YYYY',
  STANDARD_LONG: 'DD MMMM YYYY',
};

export const dateTimeFormats = {
  STANDARD: 'DD MMM YYYY h:mm A',
};

export const calendarRelativeFormats = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: dateFormats.STANDARD,
};
