import React, { Component, createContext } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { get, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import {
  appName,
  pwaFeatureEnabled,
  pwaInstallUserChoices,
  storageTypes,
  storageKeys,
  storeExpiryEnabled,
  cashCardFeatureEnabled,
  restaurantDataDepthParams,
  restaurantPremiumFeaturesKeys,
  cashCardAPIParams,
  upsellFeatureEnabled,
  subscriptionModuleEnabled,
  isScreenOrientationPortrait,
  couponMaskedRoutes,
  feedbackMaskedRoutes,
  apiResponseCodes,
  engageSpecificRoutes,
} from '../constants/globals';

import rootStore from '../store/store';
import history from '../helpers/history';
import {
  extractWhitelabelSubdomain,
  getDeviceType,
  getURLOrigin,
  isIOS,
  isMobilePlatform,
  isNativeWebView,
} from '../utils/helpers';
import { setItem, getItem } from '../utils/storage';
import asyncRoute from '../helpers/hoc/asyncRoute';
import routes from '../routes';
import rewardRoutes from '../routes/rewards';
import orderingRoutes from '../routes/ordering';
import {
  fetchAppStartupInfoAction,
  dispatchContextualHome,
  dispatchApplicableBrandModulesAction,
} from '../actions/common';
import {
  markPersistorForPurgeAction,
  dispatchUserConsentAction,
  fetchMasterDataAction,
  fetchRestaurantDataAction,
  fetchGuestCashCardsAction,
  clearProgressiveWebAppDataAction,
} from '../actions/app.action';

import { getGuestByIdAction } from '../actions/authentication';

import Spinner from '../components/Spinner';
import exceptionEmoji from '../assets/images/exception-emoji.svg';
import cancelIcon from '../assets/images/icons/close.svg';
import landscapeImage from '../assets/images/landscape.svg';

import DefaultPage from './Default';

import { Greeting } from '../components/SplashScreen/Greeting';
import './index.scss';
import pagesRoutes from '../routes/pages';

const DesktopLandingScreen = asyncRoute(() => import('../components/DesktopLandingScreen'));
const Scan = asyncRoute(() => import('./Scan'));
const Restaurant = asyncRoute(() => import('./Restaurant'));
const Cart = asyncRoute(() => import('./Cart'));
const Confirmation = asyncRoute(() => import('./Confirmation'));
const Payment = asyncRoute(() => import('./PaymentV1'));
const WelcomeScreen = asyncRoute(() => import('./WelcomeScreen'));
const OrderList = asyncRoute(() => import('./OrderList'));
const Account = asyncRoute(() => import('./Account'));
const Offers = asyncRoute(() => import('./Offers'));
const CustomLinkRedirect = asyncRoute(() => import('./CustomLinkRedirect'));
const AboutFudr = asyncRoute(() => import('./AboutFudr'));
const AboutOutlet = asyncRoute(() => import('./AboutOutlet'));
const TermsAndConditions = asyncRoute(() => import('./TermsAndConditions'));
const PrivacyPolicy = asyncRoute(() => import('./PrivacyPolicy'));
const HelpAndSupport = asyncRoute(() => import('./HelpAndSupport'));
const CashCards = asyncRoute(() => import('./CashCards'));
const DownloadApp = asyncRoute(() => import('./DownloadApp'));
const Deals = asyncRoute(() => import('./Deals'));
const CouponDetail = asyncRoute(() => import('./CouponDetail'));
const DealDetail = asyncRoute(() => import('./DealDetail'));
const NonExistentSubdomainFallback = asyncRoute(() =>
  import('./Fallback/NonExistentSubdomainFallback'),
);
const Feedback = asyncRoute(() => import('../containers/Feedback'));
const RewardsClubSignup = asyncRoute(() => import('./RewardsClubSignup'));
const Rewards = asyncRoute(() => import('./Rewards'));
const Ordering = asyncRoute(() => import('./Ordering'));
const Pages = asyncRoute(() => import('./Pages'));

const deviceType = getDeviceType();
export const DeviceContext = createContext('');
export const SubscriptionContext = createContext({});

const engageRoutes = [
  routes.containers.deals,
  routes.containers.deal,
  routes.containers.coupon,
  routes.containers.feedback,
  routes.containers.rewardsClubSignup,
];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRestaurantEntityGroup: null, // If webapp is visited through a whitelabel subdomain, this state indicates whether the corresponding restaurant entity is a group or not
      isPWAInstallable: false, // To monitor whether the PWA ready to install
      deferredPrompt: null, // Stores the PWA app install event to be triggered later
      pwaInstallUserChoice: '', // Stores string indicating whether user accepted to install the PWA or dismissed ['accepted' | 'dismissed']
      enablePWAInstallWidgets: false, // Controls display of PWA install widgets - InstallAppetizer popup, card and button
      isPWAInstalled: false, // boolean to indicate whether the PWA app is installed; not supported on Apple devices
      contextualHome: '', // Represents the home route in context to the visited domain
      currentRoute: null, //will help in storing the current route
      showDesktopLandingScreen: false,
      depthParam: restaurantDataDepthParams.config,
      isScreenOrientationPortrait,
      greetingSplashActive: true, // Show greeting splash screen upon QR scan once on app visit
      showNonExistentSubdomainFallback: false,
    };

    if (window.screen && window.screen.orientation)
      window.screen.orientation.onchange = () => {
        this.setIsScreenOrientationPortrait(!this.state.isScreenOrientationPortrait);
      };
  }

  setIsScreenOrientationPortrait = (flag) => {
    this.setState({ isScreenOrientationPortrait: flag });
  };

  componentWillMount() {
    // logic to checked whether the URL contains hash or not
    const { href, hash, pathname } = window.location;
    if (hash.includes('#')) {
      let updatedUrl = href.replace('/#', '');
      window.location.href = updatedUrl;
    }
    const lodableElement = document.getElementById('loading-background');
    if (lodableElement) {
      lodableElement.style.display = 'none';
    }
    //handle /rewards to /app redirection
    const paths = (pathname && pathname.split('/')) || [];
    if (paths.includes('rewards')) {
      const updatedPathName = paths
        .map((path) => (isEqual(path, 'rewards') ? rewardRoutes.rewards : path))
        .join('/');
      window.location.pathname = updatedPathName;
    }
  }

  componentDidMount() {
    const guestLoginData = getItem(storageTypes.localStorage, 'GuestLoginData');
    const guestId = get(guestLoginData, 'id', '');
    const { masterData } = this.props;
    //set currentTimeStamp in session storage
    setItem(storageTypes.sessionStorage, 'currentTimeStamp', moment().format());

    if (!isEqual(window.location.pathname, `/${routes.containers.payment}`)) {
      // do not get masterData and guestData if app is reloaded/redirected to payment from payment gateway
      // fetch master data
      if (isEmpty(masterData)) {
        this.props.fetchMasterDataAction({ appName });
      }

      if (!isEmpty(guestId)) {
        // rehydrate session with updated guest Data
        this.props.getGuestByIdAction({ guestId });
      }

      // Fetch guest cash cards to know whether the guest has availed cash card(s) from any outlet, only on scan screen to handle the edge case of showing My Cards.
      const currentPathName = window.location.pathname;
      currentPathName === `/${routes.containers.scan}` &&
        guestId &&
        this.getGuestCashCards(guestId);
    } else {
      // In case the payment portal opens fudr webapp ina new tab, loginData from redux store will be empty bu the guest id from localstorage will be available. We'll simply fetch the guest data at this point.
      if (isEmpty(this.props.loginData) && !isEmpty(guestId)) {
        // rehydrate session with updated guest Data
        this.props.getGuestByIdAction({ guestId });
      }
    }

    //set external origin in session storage
    const originRoute = getURLOrigin();
    this.setState({ currentRoute: originRoute });
    setItem(storageTypes.sessionStorage, storageKeys.originRoute, originRoute);

    // Redirect all whitelabel subdomain URL hits to corresponding restaurant/restaurant-group's welcome screen
    const subDomain = extractWhitelabelSubdomain();
    if (subDomain) {
      let { host, protocol, pathname } = window.location;
      pathname = pathname.split('/');
      const updatedPathName = pathname[1];
      // resolve pathname

      switch (updatedPathName) {
        case couponMaskedRoutes.coupon:
          window.location.replace(
            `${protocol}//${host}/${routes.containers.coupon}/${pathname[2]}`,
          );
          break;
        case couponMaskedRoutes.deal:
          window.location.replace(`${protocol}//${host}/${routes.containers.deal}/${pathname[2]}`);
          break;
        case feedbackMaskedRoutes.feedback:
          window.location.replace(
            `${protocol}//${host}/${routes.containers.feedback}/${pathname[2] || ''}`,
          );
          break;
        default:
          break;
      }
      if (!engageSpecificRoutes.includes(updatedPathName)) {
        // fetch  app startup data on sub domain resolution
        const appStartupReqBody = {
          subdomain: subDomain,
          findModules: true,
        };
        this.props.fetchAppStartupInfo({ appStartupReqBody }).then((response) => {
          if (apiResponseCodes.SUCCESS.includes(get(response, 'status', ''))) {
            this.props.dispatchApplicableBrandModules(get(response, 'data.modules', []));
            let redirectToPathname = '';
            const applicableModules = get(response, 'data.modules', []);
            const defaultScanCode = get(response, 'data.subdomainObj.path', '');
            if (!isEmpty(applicableModules)) {
              // Add logic to set contextual redirection
              const isRewardProgramEnabled = get(
                applicableModules.find((module) => module.code === 'reward'),
                'isActive',
              );
              const isOrderingEnabled = get(
                applicableModules.find((module) => module.code === 'onlineOrder'),
                'isActive',
              );
              if (isEqual(updatedPathName, rewardRoutes.rewards)) {
                if (isRewardProgramEnabled) {
                  this.props.dispatchContextualHome(`/${rewardRoutes.rewards}`);
                  redirectToPathname = `/${rewardRoutes.rewards}`;
                } else {
                  this.setState({ showNonExistentSubdomainFallback: true });
                }
              } else if (isEqual(updatedPathName, orderingRoutes.ordering)) {
                //commenting for now as isOrderingEnabled is always false for all the cases
                // if (isOrderingEnabled) {
                //   this.props.dispatchContextualHome(`/${orderingRoutes.ordering}`);
                //   redirectToPathname = `/${orderingRoutes.ordering}`;
                // } else {
                //   this.setState({ showNonExistentSubdomainFallback: true });
                // }
                this.props.dispatchContextualHome(`/${orderingRoutes.ordering}`);
                redirectToPathname = `/${orderingRoutes.ordering}`;
              } else {
                if (isRewardProgramEnabled) {
                  this.props.dispatchContextualHome(`/${rewardRoutes.rewards}`);
                  redirectToPathname = `/${rewardRoutes.rewards}`;
                } else {
                  this.props.dispatchContextualHome(
                    `/${routes.containers.welcome}/${defaultScanCode}`,
                  );
                  redirectToPathname = `/${routes.containers.welcome}/${defaultScanCode}`;
                }
              }

              // Avoid redirection from these exception routes
              let exceptionRoutes = [
                routes.containers.payment,
                routes.containers.confirmation,
                routes.containers.welcome,
                routes.containers.cart,
                routes.containers.offers,
                routes.containers.account,
                routes.containers.aboutOutlet,
                routes.containers.getApp,
                rewardRoutes.rewards,
                orderingRoutes.ordering,
                pagesRoutes.pages,
              ];
              if (!exceptionRoutes.some((route) => window.location.pathname.indexOf(route) > -1)) {
                window.location.replace(window.location.origin + redirectToPathname);
              }
              // For Apple devices, simply enable the PWA widgets, now that the whitelabel info exists
              this.enablePWAInstallWidgetsOnIOS();
            }
          } else {
            //In case no whitelabel info exists for the subdomain visited show fallback ui
            if (!isEqual(updatedPathName, rewardRoutes.rewards)) {
              this.setState({
                showNonExistentSubdomainFallback: true,
              });
            }
          }
        });
      } else {
        if (isNativeWebView && engageSpecificRoutes.includes(updatedPathName)) {
          window.location.replace(window.location.origin + `/${rewardRoutes.rewards}`);
        }
        // For Apple devices, simply enable the PWA widgets, if the whitelabel info exists
        this.enablePWAInstallWidgetsOnIOS();
      }
    } else {
      // In case whitelabel subdomain is not valid
      // check for the scan route and deviceType
      if (
        (isEqual(window.location.pathname, `/${routes.containers.scan}`) ||
          isEqual(window.location.pathname, '/')) &&
        isEqual(deviceType, 'desktop')
      ) {
        this.setState({
          showDesktopLandingScreen: true,
        });
      }
      this.props.dispatchContextualHome(`/${routes.containers.scan}`);
    }

    this.enablePWAWidgetsForDiscovery();

    // Detect if the client supports the Add to home screen (PWA) feature and stash the event to be triggered later. Not supported on Apple devices.
    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevent the mini-infobar from appearing by default
      event.preventDefault();

      // If it's a whitelabel visit, set true, otherwise check if the restaurantAppLink exists
      const enablePWAInstallWidgets = subDomain ? true : !!this.props.restaurantAppLink;

      // Stash the event so it can be triggered later and place PWA install widgets into DOM
      this.setState((prevState) => ({
        deferredPrompt: event,
        ...(!prevState.isPWAInstalled && { enablePWAInstallWidgets }),
        isPWAInstallable: true,
      }));
    });

    // Listen for the appinstalled event to track if the PWA was successfully installed. Not supported on Apple devices.
    window.addEventListener('appinstalled', (event) => {
      this.setPWAInstalledState(true);
      this.hidePWAInstallWidgets();
    });

    this.checkIsPWAAlreadyInstalled();
  }

  componentWillReceiveProps(nextProps) {
    // If session is marked for a clear, then clear the session and redirect to home
    if (
      !!storeExpiryEnabled &&
      !isEqual(
        get(nextProps, 'persistorMarkedForPurge'),
        get(this.props, 'persistorMarkedForPurge'),
      ) &&
      get(nextProps, 'persistorMarkedForPurge') === true
    ) {
      this.clearSessionAndRedirect();
    }

    //will store previous route of current route in window.history.state
    if (this.state.currentRoute) {
      const { origin, pathname } = window.location;
      const URL = `${origin}${pathname}`;
      if (!isEqual(get(this.state, 'currentRoute'), URL)) {
        setItem(
          storageTypes.sessionStorage,
          storageKeys.originRoute,
          get(this.state, 'currentRoute'),
        );
        this.setState({ currentRoute: URL });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const currentLoginData = this.props.loginData;
    const previousLoginData = prevProps.loginData;

    if (!isEmpty(currentLoginData) && currentLoginData !== previousLoginData) {
      const guestId = get(currentLoginData, 'id', '');

      const currentPathName = window.location.pathname;

      // Fetch guest cash cards to know whether the guest has availed cash card(s) from any outlet, only on scan screen to handle the edge case of showing My Cards.
      if (currentPathName === `/${routes.containers.scan}`) {
        guestId && this.getGuestCashCards(guestId);
      }
    }

    const currentRestaurantAppLink = this.props.restaurantAppLink;
    const previousRestaurantAppLink = prevProps.restaurantAppLink;

    if (currentRestaurantAppLink !== previousRestaurantAppLink) {
      this.enablePWAWidgetsForDiscovery();
    }
  }

  handleContextualRedirection = () => {};

  renderGreetingSplashScreenComponent = (container) => {
    const { guestData, restaurantGroup, restaurant, appStartupInfo } = this.props;
    const { greetingSplashActive } = this.state;

    const guestName = get(guestData, 'name', '');
    const restaurantEntityName = !isEmpty(restaurantGroup)
      ? get(restaurantGroup, 'name')
      : get(restaurant, 'name')
      ? get(restaurant, 'name')
      : get(appStartupInfo, 'title');

    // Guest-visited restaurant related variables
    const guestVisitedRestaurants = get(guestData, 'visitedRestaurants', {}); // Restaurants where the guest has previously ordered
    const visitedRestaurantCode = get(restaurant, 'restaurantCode', '');
    const hasGuestVisitedRestaurantBefore = this.hasGuestVisitedRestaurantBefore(
      guestVisitedRestaurants,
      visitedRestaurantCode,
    );
    return (
      <Greeting
        active={greetingSplashActive}
        deactivate={() => this.setGreetingSplashActive(false)}
        loggedInGuestName={guestName}
        revisit={hasGuestVisitedRestaurantBefore}
        outletName={restaurantEntityName}
        showTimeSalutation
        container={container}
      />
    );
  };

  hasGuestVisitedRestaurantBefore = (guestVisitedRestaurants = [], visitedRestaurantCode = '') =>
    !isEmpty(guestVisitedRestaurants) && guestVisitedRestaurants.includes(visitedRestaurantCode);

  setGreetingSplashActive = (flag) => {
    this.setState({ greetingSplashActive: flag });
  };

  // Check if the app is already installed on the device, and update the isPWAInstalled state accordingly
  checkIsPWAAlreadyInstalled = async () => {
    if (!('getInstalledRelatedApps' in navigator)) return;

    const relatedApps = await navigator.getInstalledRelatedApps();

    if (!isEmpty(relatedApps)) {
      const appAlreadyInstalled = relatedApps.some((app) => app.platform === 'webapp');
      appAlreadyInstalled && this.setState({ isPWAInstalled: true });
    }
  };

  getGuestCashCards = (guestId) => {
    const pageSize = cashCardAPIParams.guestCashCards.pageSize;
    const sortType = cashCardAPIParams.guestCashCards.sortType;

    const constructedURL = `/cashCards/guests?guestId=${guestId}&size=${pageSize}&sort=${sortType}`;

    this.props.fetchGuestCashCards({ url: constructedURL });
  };

  handleInstallPWAClick = () => {
    const promptEvent = this.state.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available
      return;
    }
    // Show the PWA install prompt
    promptEvent.prompt();
    // Detect if the user chose to install PWA
    promptEvent.userChoice.then((result) => {
      const pwaInstallUserChoice = get(result, 'outcome', '');
      this.setState({ pwaInstallUserChoice });

      if (pwaInstallUserChoice === pwaInstallUserChoices.accepted) {
        // Reset the deferred prompt variable, since prompt() can only be called once
        this.setState({ deferredPrompt: null });
        this.setPWAInstalledState(true);
        this.hidePWAInstallWidgets();
      }
    });
  };

  hidePWAInstallWidgets = () => {
    this.setState({
      enablePWAInstallWidgets: false,
    });
  };

  setPWAInstalledState = (isPWAInstalled) => {
    this.setState({
      isPWAInstalled,
    });
  };

  enablePWAInstallWidgetsOnIOS = () => {
    deviceType === 'ios' &&
      this.setState({
        isPWAInstallable: true, // PWA is immediately available to install on iOS Safari on webapp load
        enablePWAInstallWidgets: true,
      });
  };

  // To promote PWA discovery, in case of non-whitelabel visit to the webapp, enable PWA install widgets if the restaurant's get-app link exists
  enablePWAWidgetsForDiscovery = () => {
    const subdomain = extractWhitelabelSubdomain();
    !subdomain &&
      this.setState({
        enablePWAInstallWidgets: !isEmpty(this.props.restaurantAppLink) ? true : false,
      });
  };

  clearSessionAndRedirect = () => {
    const { persistor } = rootStore;
    // Clear the store being persisted from session storage
    persistor
      .purge()
      .then(() => {
        this.props.markPersistorForPurgeAction(false).then(() => {
          window.location.replace(window.location.origin);
        });
      })
      .catch((error) => {
        console.error('There was an error purging the store', error);
      });
  };

  handleRedirectToOrderingSiteClick = () => {
    this.setState(
      {
        showDesktopLandingScreen: false,
      },
      () => {
        this.props.dispatchUserConsentAction({
          redirectToOrderingSite: true,
        });
      },
    );
  };

  resolveScanRouteComponent = ({
    props,
    showDesktopLandingScreen,
    redirectToOrderingSite,
    showCashCardFeatureOnScanScreenFlag,
  }) => {
    let component = '';
    if (showDesktopLandingScreen && !redirectToOrderingSite) {
      component = (
        <DesktopLandingScreen
          handleRedirectToOrderingSiteClick={this.handleRedirectToOrderingSiteClick}
        />
      );
    } else {
      component = <Scan {...props} showCashCardFeature={showCashCardFeatureOnScanScreenFlag} />;
    }
    return component;
  };

  getPremiumFeaturesEnabledForRestaurant = (restaurantConfigAdditionalProps) => {
    const restaurantPremiumFeaturesObject =
      restaurantConfigAdditionalProps.find((prop) => get(prop, 'code') === 'plan.plus.features') ||
      {};
    const restaurantPremiumFeaturesList = get(restaurantPremiumFeaturesObject, 'value', '').split(
      ',',
    ); // Get comma separated feature keys and return them in an array

    const restaurantSubscribedToPWAFeature = restaurantPremiumFeaturesList.includes(
      restaurantPremiumFeaturesKeys.pwa,
    );
    const restaurantSubscribedToUpsellFeature = restaurantPremiumFeaturesList.includes(
      restaurantPremiumFeaturesKeys.upsell,
    );
    const restaurantSubscribedToCashCardFeature = restaurantPremiumFeaturesList.includes(
      restaurantPremiumFeaturesKeys.cashCard,
    );

    return {
      restaurantSubscribedToPWAFeature,
      restaurantSubscribedToUpsellFeature,
      restaurantSubscribedToCashCardFeature,
    };
  };

  handleGoHome = () => {
    window.location.replace(window.location.origin);
  };

  componentWillUnmount() {
    this.props.clearProgressiveWebAppData();
  }

  render() {
    const {
      redirectToOrderingSite,
      showSpinner,
      contextualHome,
      restaurantConfigAdditionalProps,
      guestCashCards,
    } = this.props;

    const {
      isRestaurantEntityGroup,
      enablePWAInstallWidgets,
      showDesktopLandingScreen,
      isPWAInstalled,
      pwaInstallUserChoice,
      isPWAInstallable,
      isScreenOrientationPortrait,
      showNonExistentSubdomainFallback,
    } = this.state;

    let restaurantSubscribedToUpsellFeature = true,
      restaurantSubscribedToCashCardFeature = true;

    if (!subscriptionModuleEnabled) {
      // Restaurant-specific premium feature subscription flags
      restaurantSubscribedToUpsellFeature = get(
        this.getPremiumFeaturesEnabledForRestaurant(restaurantConfigAdditionalProps),
        'restaurantSubscribedToUpsellFeature',
      );
      restaurantSubscribedToCashCardFeature = get(
        this.getPremiumFeaturesEnabledForRestaurant(restaurantConfigAdditionalProps),
        'restaurantSubscribedToCashCardFeature',
      );
    }

    // PWA feature related flags
    const showPWAFeatureFlag = pwaFeatureEnabled;

    // Upsell feature related flags
    const showUpsellFeatureFlag = upsellFeatureEnabled && restaurantSubscribedToUpsellFeature;

    // Cash-card feature related flags
    const showCashCardFeatureFlag = cashCardFeatureEnabled && restaurantSubscribedToCashCardFeature;
    const hasGuestAvailedCashCards = !isEmpty(guestCashCards);
    const showCashCardFeatureOnScanScreenFlag = hasGuestAvailedCashCards;

    const levelOneSubPath = window.location.pathname ? window.location.pathname.split('/')[1] : '';
    const isRouteEngageScoped = engageRoutes.includes(levelOneSubPath);

    const isPageContext = pagesRoutes.pages.includes(levelOneSubPath);

    return (
      <DeviceContext.Provider value={deviceType}>
        <SubscriptionContext.Provider
          value={{
            pwa: showPWAFeatureFlag,
            upsell: showUpsellFeatureFlag,
            cashCard: showCashCardFeatureFlag,
          }}
        >
          {!isNativeWebView && isMobilePlatform() && (
            <div
              className={classNames('landscape-not-supported-guide', {
                visible: !isScreenOrientationPortrait,
                ios: isIOS(),
              })}
            >
              <img
                className="landscape-placeholder-image"
                src={landscapeImage}
                alt="landscape not supported"
              />
            </div>
          )}
          <div
            className={classNames('container', {
              engage: isRouteEngageScoped,
              page: isPageContext,
            })}
          >
            {showSpinner && !showDesktopLandingScreen && <Spinner />}
            {showNonExistentSubdomainFallback ? (
              // handle GoHome should redirect to the base
              <NonExistentSubdomainFallback
                resources={{
                  title: 'Unable to reach this page',
                  description: `It seems some error has occured.
     Please try again`,
                  atnBtn: 'TRY AGAIN',
                }}
                handleGoHome={this.handleGoHome}
              />
            ) : (
              <Router history={history}>
                <Switch>
                  <Route
                    path={`/${routes.containers.scan}`}
                    render={(props) =>
                      this.resolveScanRouteComponent({
                        props,
                        showDesktopLandingScreen,
                        redirectToOrderingSite,
                        showCashCardFeatureOnScanScreenFlag,
                      })
                    }
                  />
                  {/* <Ordering/> */}
                  <Route
                    path={`/ordering`}
                    render={(props) => <Ordering {...props} showWebElements={true} />}
                  />
                  <Route path={`/${pagesRoutes.pages}`} render={(props) => <Pages {...props} />} />
                  <Route
                    path={`/${routes.containers.restaurant}/:restaurantAndTableId`}
                    render={(props) => (
                      <Restaurant
                        {...props}
                        enablePWAInstallWidgets={enablePWAInstallWidgets}
                        handleInstallPWAClick={this.handleInstallPWAClick}
                        pwaFeatureEnabled={showPWAFeatureFlag}
                        cashCardFeatureEnabled={showCashCardFeatureFlag}
                      />
                    )}
                  />
                  <Route path={`/${routes.containers.cart}`} component={Cart} />
                  <Route
                    path={`/${routes.containers.confirmation}/:orderId`}
                    render={(props) => (
                      <Confirmation
                        {...props}
                        enablePWAInstallWidgets={enablePWAInstallWidgets}
                        handleInstallPWAClick={this.handleInstallPWAClick}
                        pwaFeatureEnabled={showPWAFeatureFlag}
                      />
                    )}
                  />
                  <Route path={`/${routes.containers.payment}`} component={Payment} />
                  <Route
                    path={`/${routes.containers.orders}`}
                    render={(props) => (
                      <OrderList {...props} isRestaurantEntityGroup={isRestaurantEntityGroup} />
                    )}
                  />
                  {cashCardFeatureEnabled && (
                    <Route
                      path={`/${routes.containers.cashCards}`}
                      render={(props) => (
                        <CashCards {...props} isRestaurantEntityGroup={isRestaurantEntityGroup} />
                      )}
                    />
                  )}
                  <Route path={`/${routes.containers.account}`} component={Account} />
                  <Route
                    path={`/${routes.containers.welcome}/:restaurantAndTableId`}
                    render={(props) => (
                      <WelcomeScreen
                        {...props}
                        greetingSplashScreen={this.renderGreetingSplashScreenComponent(
                          routes.containers.welcome,
                        )}
                      />
                    )}
                  />
                  <Route path={`/${routes.containers.offers}`} component={Offers} />
                  <Route
                    exact
                    path={`/${routes.containers.custom}/:customLinkKey`}
                    component={CustomLinkRedirect}
                  />
                  <Route path={`/${routes.containers.aboutFudr}`} component={AboutFudr} />
                  <Route
                    path={`/${routes.containers.aboutOutlet}/:restaurantId`}
                    component={AboutOutlet}
                  />
                  <Route
                    path={`/${routes.containers.termsAndConditions}`}
                    component={TermsAndConditions}
                  />
                  <Route path={`/${routes.containers.privacyPolicy}`} component={PrivacyPolicy} />
                  <Route path={`/${routes.containers.helpAndSupport}`} component={HelpAndSupport} />
                  <Route
                    path={`/${routes.containers.deals}`}
                    render={(props) => (
                      <Deals
                        {...props}
                        greetingSplashScreen={this.renderGreetingSplashScreenComponent()}
                      />
                    )}
                  />
                  <Route
                    path={`/${routes.containers.coupon}/:couponCode`}
                    render={(props) => (
                      <CouponDetail
                        {...props}
                        greetingSplashScreen={this.renderGreetingSplashScreenComponent()}
                      />
                    )}
                  />
                  <Route
                    path={`/${routes.containers.deal}/:dealCode`}
                    render={(props) => (
                      <DealDetail
                        {...props}
                        greetingSplashScreen={this.renderGreetingSplashScreenComponent()}
                      />
                    )}
                  />
                  <Route
                    path={`/${routes.containers.feedback}`}
                    render={(props) => (
                      <Feedback
                        {...props}
                        greetingSplashScreen={this.renderGreetingSplashScreenComponent(
                          routes.containers.feedback,
                        )}
                      />
                    )}
                  />
                  <Route
                    path={`/${routes.containers.rewardsClubSignup}`}
                    render={(props) => (
                      <RewardsClubSignup
                        {...props}
                        greetingSplashScreen={this.renderGreetingSplashScreenComponent()}
                      />
                    )}
                  />
                  <Route
                    path={`/${rewardRoutes.rewards}`}
                    render={(props) => <Rewards {...props} />}
                  />
                  {showPWAFeatureFlag && (
                    <Route
                      path={`/${routes.containers.getApp}`}
                      render={(props) => (
                        <DownloadApp
                          {...props}
                          handleInstallPWAClick={this.handleInstallPWAClick}
                          enablePWAInstallWidgets={enablePWAInstallWidgets}
                          isPWAInstalled={isPWAInstalled}
                          pwaInstallUserChoice={pwaInstallUserChoice}
                          isPWAInstallable={isPWAInstallable}
                        />
                      )}
                    />
                  )}
                  {/* Default route */}
                  {isEmpty(contextualHome) ? (
                    <Route path="/" component={DefaultPage} />
                  ) : (
                    <Redirect to={contextualHome} />
                  )}
                </Switch>
              </Router>
            )}
            {/* Load any resources needed to be cached in case user agent goes offline */}
            <span style={{ display: 'none' }}></span>
            <img src={exceptionEmoji} style={{ display: 'none' }} alt="exception" />
            <img src={cancelIcon} style={{ display: 'none' }} alt="cancel-icon" />
          </div>
        </SubscriptionContext.Provider>
      </DeviceContext.Provider>
    );
  }
}

export default connect(
  (state) => ({
    loginData: get(state, 'authentication.loginData', {}),
    persistorMarkedForPurge: get(state, 'app.persistorMarkedForPurge', false),
    redirectToOrderingSite: get(state, 'app.userConsent.redirectToOrderingSite', false),
    showSpinner: get(state, 'app.showSpinner', false),
    contextualHome: get(state, 'common.contextualHome', ''),
    appStartupInfo: get(state, 'common.appStartupInfo.subdomainObj', {}),
    restaurantConfigAdditionalProps: get(
      state,
      'app.restaurant.restaurantConfig.additionalProperties',
      [],
    ),
    guestCashCards: get(state, 'app.guestCashCards._embedded.guestCashCards', []),
    restaurantAppLink: get(state, 'app.progressiveWebApp.appLink', ''),
    restaurantGroup: get(state, 'app.restaurantGroup', {}),
    restaurant: get(state, 'app.restaurant', {}),
    guestData: get(state, 'authentication.guestData', {}),
    masterData: get(state, 'common.masterData', {}),
  }),
  {
    fetchAppStartupInfo: fetchAppStartupInfoAction,
    markPersistorForPurgeAction,
    dispatchUserConsentAction,
    fetchMasterDataAction,
    getGuestByIdAction,
    dispatchContextualHome,
    fetchRestaurantDataAction,
    fetchGuestCashCards: fetchGuestCashCardsAction,
    clearProgressiveWebAppData: clearProgressiveWebAppDataAction,
    dispatchApplicableBrandModules: dispatchApplicableBrandModulesAction,
  },
)(App);
