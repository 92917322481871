import { get } from 'lodash';
import { BEGIN_AJAX_CALL, END_AJAX_CALL } from '../common/actionType';
import {
  ATTEMPT_LOGIN_SUCCESS,
  CLEAR_GUEST_DATA,
  CREATE_BRAND_GUEST,
  DISPATCH_AUTH_TOKEN,
  DISPATCH_GUEST_DATA,
  DISPATCH_SIGNIN_DATA,
} from './actionType';
import {
  addBrandGuestApi,
  addFudrGuestApi,
  attemptLoginApi,
  findGuestByPhoneNumberApi,
  getGuestByIdApi,
  getUserProfileDataApi,
  sendOtpApi,
  updateProfileApi,
  signInByTrueCallerApi,
} from '../../api/api';
import { apiErrorResponseAction, beginAjaxCallAction, endAjaxCallAction } from '../common';
import {
  CLEAR_SELECTED_ADDRESS,
  FETCH_GUEST_ORDERS,
  FETCH_SELECTED_TABLE,
  FETCH_VIEW_CART,
} from '../ordering/actionType';
import { storageTypes } from '../../constants/globals';
import {
  CLEAR_GUEST_CASH_CARDS,
  CLEAR_VERIFIED_LOCALITY,
  DISPATCH_REQUESTED_ASSISTANCES_TIMESTAMP,
} from '../actionType';
import { EMPTY_REWARD_GUEST_DETAILS, FETCH_GUEST_LAST_ORDER, UPDATE_GUEST_COUPON_FILTER } from '../rewards/actionType';
import { clearGuestPreviousOrderedItemsAction } from '../ordering';
import { getItem } from '../../utils/storage';
import { isNativeWebView } from '../../utils/helpers';

export const dispatchGuestDataAction = (guestData) => async (dispatch) => {
  dispatch({
    type: DISPATCH_GUEST_DATA,
    guestData,
  });
};

export const clearGuestDataAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GUEST_DATA,
  });
};

export const dispatchSignInDataAction = (signIn) => async (dispatch) => {
  dispatch({
    type: DISPATCH_SIGNIN_DATA,
    signIn,
  });
};

export const attemptLoginAction = (loginReqBody) => async (dispatch) => {
  let loginData = {};
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const loginDataResponse = await attemptLoginApi(loginReqBody)
      .then((response) => {
        loginData = get(response, 'data', {});
        localStorage.setItem('GuestLoginData', JSON.stringify(get(loginData, 'guest', {})));
        localStorage.setItem('AuthData', JSON.stringify(get(loginData, 'auth', {})));
        dispatch({
          type: ATTEMPT_LOGIN_SUCCESS,
          loginData: get(loginData, 'guest', {}),
        });
        dispatch({
          type: DISPATCH_AUTH_TOKEN,
          authData: get(loginData, 'auth', {}),
        });
        dispatch(dispatchGuestDataAction(get(loginData, 'guest', {})));
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response.data', {});
      });
    return loginDataResponse;
  } catch (err) {
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const updateProfileAction = (updateProfileReqBody, guestId) => async (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const updateProfileData = await updateProfileApi({
      updateProfileReqBody,
      guestId,
    })
      .then((response) => {
        localStorage.setItem('GuestLoginData', JSON.stringify(get(response, 'data', {})));
        dispatch({
          type: ATTEMPT_LOGIN_SUCCESS,
          loginData: get(response, 'data', {}),
        });
        dispatch(dispatchGuestDataAction(get(response, 'data', {})));
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response.data', {});
      });
    return updateProfileData;
  } catch (err) {
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const getUserProfileDataAction = (guestId) => async (dispatch) => {
  let userProfileData = {};
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    userProfileData = await getUserProfileDataApi({ guestId });
    localStorage.setItem('GuestLoginData', JSON.stringify(get(userProfileData, 'data', {})));
    dispatch({
      type: ATTEMPT_LOGIN_SUCCESS,
      loginData: get(userProfileData, 'data', {}),
    });
  } catch (err) {
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const getGuestByIdAction =
  ({ guestId }) =>
  async (dispatch) => {
    let guestData = {};
    try {
      guestData = await getGuestByIdApi({ guestId });
      localStorage.setItem('GuestLoginData', JSON.stringify(guestData));
      dispatch({
        type: ATTEMPT_LOGIN_SUCCESS,
        loginData: guestData,
      });
      dispatch({
        type: DISPATCH_AUTH_TOKEN,
        authData: getItem(storageTypes.localStorage, 'AuthData'),
      });
      dispatch(dispatchGuestDataAction(guestData));
    } catch (err) {
      console.log(err);
    }
  };

export const dispatchGuestLoginDataAction = (guestLoginData) => async (dispatch) => {
  localStorage.setItem('GuestLoginData', JSON.stringify(get(guestLoginData, 'guest', {})));
  localStorage.setItem('AuthData', JSON.stringify(get(guestLoginData, 'auth', {})));
  dispatch({
    type: ATTEMPT_LOGIN_SUCCESS,
    loginData: get(guestLoginData, 'guest', {}),
  });
  dispatch({
    type: DISPATCH_AUTH_TOKEN,
    authData: get(guestLoginData, 'auth', {}),
  });
};

export const dispatchLogoutAction = () => (dispatch) => {
  try {
    if (isNativeWebView) {
      const data = {
        eventType: { type: 'logout' },
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    localStorage.setItem('GuestLoginData', JSON.stringify({}));
    localStorage.setItem('AuthData', JSON.stringify({}));
    dispatch({
      type: ATTEMPT_LOGIN_SUCCESS,
      loginData: {},
    });
    dispatch({
      type: FETCH_GUEST_LAST_ORDER,
      guestLastOrder: {},
    });
    dispatch({
      type: DISPATCH_AUTH_TOKEN,
      authData: {},
    });
    dispatch({
      type: DISPATCH_REQUESTED_ASSISTANCES_TIMESTAMP,
      requestedAssistancesTimeStampArr: [],
    });
    dispatch({
      type: FETCH_GUEST_ORDERS,
      guestOrders: {},
    });
    dispatch({
      type: EMPTY_REWARD_GUEST_DETAILS,
    });
    // Clear guest cash card list
    dispatch({
      type: CLEAR_GUEST_CASH_CARDS,
    });
    dispatch({ type: CLEAR_VERIFIED_LOCALITY });
    dispatch(clearGuestDataAction());
    // Clear the guest's previous orders list
    dispatch(clearGuestPreviousOrderedItemsAction());
    //clear the reward history
    dispatch({ type: UPDATE_GUEST_COUPON_FILTER, filter: {} });
    // empty cart
    dispatch({
      type: FETCH_VIEW_CART,
      items: {},
    });
    // EMPTY TABLE
    dispatch({
      type: FETCH_SELECTED_TABLE,
      selectedTable: {},
    });
    dispatch({
      type: CLEAR_SELECTED_ADDRESS,
    });
  } catch (err) {
    console.error(err);
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const findGuestByPhoneNumberAction = (phoneNumber) => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    const findGuestByPhoneNumberAPIResponse = await findGuestByPhoneNumberApi({
      phoneNumber,
    })
      .then((response) => {
        return response;
      })
      .catch((errorResponse) => {
        // dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return findGuestByPhoneNumberAPIResponse;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

export const sendOtpAction = (phoneNumber, countryCode, res) => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    const sendOtpResponse = await sendOtpApi(phoneNumber, countryCode, res)
      .then((response) => {
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });
    return sendOtpResponse;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(endAjaxCallAction());
  }
};

export const addFudrGuestAction =
  ({ signupReqBody }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());
      const addFudrGuestAPIResponse = await addFudrGuestApi({
        signupReqBody,
      })
        .then((response) => {
          return response;
        })
        .catch((errorResponse) => {
          return get(errorResponse, 'response', {});
        });
      return addFudrGuestAPIResponse;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const addBrandGuestAction =
  ({ addBrandGuestReqBody }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());
      const addBrandGuestAPIResponse = await addBrandGuestApi({
        addBrandGuestReqBody,
      })
        .then((response) => {
          dispatch({
            type: CREATE_BRAND_GUEST,
            brandGuest: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          return get(errorResponse, 'response', {});
        });
      return addBrandGuestAPIResponse;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const signInByTrueCallerAction =
  ({ requestId }) =>
  async () => {
    const signInByTrueCallerResponse = await signInByTrueCallerApi({ requestId })
      .then((response) => {
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });
    return signInByTrueCallerResponse;
  };
