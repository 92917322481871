// added namespacing to avoid interference with other plugins or redux stores

export const PURGE = 'persist/PURGE'; // Action type for redux store persistor purging (purging means clearing)

export const MARK_PERSISTOR_FOR_PURGE = 'persist/MARK_PERSISTOR_FOR_PURGE';

export const BASE = 'BASE/';

export const DISPATCH_USER_CONSENT = `${BASE}DISPATCH_USER_CONSENT`;

export const FETCH_MASTER_DATA = `${BASE}FETCH_MASTER_DATA`;

export const FETCH_RESTAURANT_MENU = `${BASE}FETCH_RESTAURANT_MENU`;

export const FETCH_RESTAURANT_DATA = `${BASE}FETCH_RESTAURANT_DATA`;

export const FETCH_RESTAURANT_LIST = `${BASE}FETCH_RESTAURANT_LIST`;

export const FETCH_AVAILABLE_CITIES = `${BASE}FETCH_AVAILABLE_CITIES`;

export const FIND_GUEST_LOCATION = `${BASE}FIND_GUEST_LOCATION`;

export const UPDATE_MENU = `${BASE}UPDATE_MENU`;

export const FETCH_MENU_ITEM_CUSTOMIZATIONS = `${BASE}FETCH_MENU_ITEM_CUSTOMIZATIONS`;

export const FETCH_VIEW_CART = `${BASE} FETCH_VIEW_CART`;

export const PLACE_ORDER = `${BASE} PLACE_ORDER`;

export const FETCH_ORDER_STATUS = `${BASE} FETCH_ORDER_STATUS`;

export const FETCH_ORDERED_ITEMS = `${BASE} FETCH_ORDERED_ITEMS`;

export const ATTEMPT_LOGIN_SUCCESS = `${BASE} ATTEMPT_LOGIN_SUCCESS`;

export const BEGIN_AJAX_CALL = `${BASE} BEGIN_AJAX_CALL`;

export const END_AJAX_CALL = `${BASE} END_AJAX_CALL`;

export const CLEAR_SELECTED_TABLE = `${BASE} CLEAR_SELECTED_TABLE`;

export const CLEAR_UPDATED_SCAN_CODE = `${BASE} CLEAR_UPDATED_SCAN_CODE`;

export const DISPATCH_SIGNIN_DATA = `${BASE} DISPATCH_SIGNIN_DATA`;

export const DISPATCH_AUTH_TOKEN = `${BASE} DISPATCH_AUTH_TOKEN`;

export const GET_CURRENT_ORDER_COUNT = `${BASE} GET_CURRENT_ORDER_COUNT`;

export const FETCH_GUEST_ORDERS = `${BASE} FETCH_GUEST_ORDERS`;

export const API_ERROR_RESPONSE = `API_ERROR_RESPONSE`;

export const DISABLED_MENU_ITEMS = `${BASE} DISABLED_MENU_ITEMS`;

export const REQUEST_ASSISTANCE = `${BASE} REQUEST_ASSISTANCE`;

export const DISPATCH_REQUESTED_ASSISTANCES_TIMESTAMP = `${BASE} DISPATCH_REQUESTED_ASSISTANCES_TIMESTAMP`;

export const DISPATCH_VERIFIED_LOCALITIES = `${BASE}DISPATCH_VERIFIED_LOCALITIES`;

export const FETCH_SELECTED_TABLE = `${BASE}FETCH_SELECTED_TABLE`;

export const PREVIOUS_SELECTED_TABLE = `${BASE}PREVIOUS_SELECTED_TABLE`;

export const FETCH_LOCALITIES = `${BASE}FETCH_LOCALITIES`;

export const DISPATCH_SELECTED_ADDRESS = `${BASE}DISPATCH_SELECTED_ADDRESS`;

export const DISPATCH_RESTAURANT_GROUP = `${BASE}DISPATCH_RESTAURANT_GROUP`;

export const DISPATCH_CUSTOMIZATION_LOADER = `${BASE}DISPATCH_CUSTOMIZATION_LOADER`;

export const FETCH_RESTAURANT_OFFERS = `${BASE}FETCH_RESTAURANT_OFFERS`;

export const DISPATCH_USER_CART = `${BASE}DISPATCH_USER_CART`;

export const DELETE_GUEST_VEHICLE = `${BASE}DELETE_GUEST_VEHICLE`;

export const DISPATCH_PARENT_ROUTE = `${BASE}DISPATCH_PARENT_ROUTE`;

export const FETCH_AVAILABLE_OFFERS = `${BASE}FETCH_AVAILABLE_OFFERS`;

export const DISPATCH_CONTEXTUAL_HOME = `${BASE}DISPATCH_CONTEXTUAL_HOME`;

export const CART_UPSELL_ITEMS = `${BASE}CART_UPSELL_ITEMS`;

export const FETCH_GUEST_CASH_CARDS = `${BASE}FETCH_GUEST_CASH_CARDS`;

export const CLEAR_GUEST_CASH_CARDS = `${BASE}CLEAR_GUEST_CASH_CARDS`;

export const FETCH_GUEST_CASH_CARD_DETAILS = `${BASE}FETCH_GUEST_CASH_CARD_DETAILS`;

export const CLEAR_GUEST_CASH_CARD_DETAILS = `${BASE}CLEAR_GUEST_CASH_CARD_DETAILS`;

export const FETCH_GUEST_CASH_CARD_TRANSACTIONS = `${BASE}FETCH_GUEST_CASH_CARD_TRANSACTIONS`;

export const CLEAR_GUEST_CASH_CARD_TRANSACTIONS = `${BASE}CLEAR_GUEST_CASH_CARD_TRANSACTIONS`;

export const FETCH_RESTAURANT_CASH_CARD = `${BASE}FETCH_RESTAURANT_CASH_CARD`;

export const CLEAR_RESTAURANT_CASH_CARD = `${BASE}CLEAR_RESTAURANT_CASH_CARD`;

export const CREATE_CASH_CARD_RECHARGE = `${BASE}CREATE_CASH_CARD_RECHARGE`;

export const CLEAR_CASH_CASH_RECHARGE = `${BASE}CLEAR_CASH_CASH_RECHARGE`;

export const DISPATCH_LAST_CASH_CARD_RECHARGE_TRANSACTION = `${BASE}DISPATCH_LAST_CASH_CARD_RECHARGE_TRANSACTION`;

export const CLEAR_VERIFIED_LOCALITY = `${BASE}CLEAR_VERIFIED_LOCALITY`;

export const DISPATCH_PROGRESSIVE_WEB_APP_DATA = 'DISPATCH_PROGRESSIVE_WEB_APP_DATA';

export const CLEAR_PROGRESSIVE_WEB_APP_DATA = 'CLEAR_PROGRESSIVE_WEB_APP_DATA';

export const DISPATCH_GUEST_DATA = 'DISPATCH_GUEST_DATA';

export const CLEAR_GUEST_DATA = 'CLEAR_GUEST_DATA';

export const FETCH_PREVIOUS_ORDERS = 'FETCH_PREVIOUS_ORDERS';

export const CLEAR_PREVIOUS_ORDERS = 'CLEAR_PREVIOUS_ORDERS';

// engage specific action types

export const FETCH_BRAND_DEALS = 'FETCH_BRAND_DEALS';

export const CLEAR_BRAND_DEALS = 'CLEAR_BRAND_DEALS';

export const FETCH_SUBSCRIBED_DEAL = 'FETCH_SUBSCRIBED_DEAL';

export const FETCH_BRAND_DEAL_DATA = 'FETCH_BRAND_DEAL_DATA';

export const CLEAR_BRAND_DEAL_DATA = 'CLEAR_BRAND_DEAL_DATA';

export const UPDATE_DEAL_GUEST = 'UPDATE_DEAL_GUEST';

export const DISPATCH_GUEST_VISITED_RESTAURANTS = 'DISPATCH_GUEST_VISITED_RESTAURANTS';

export const FETCH_OUTLET_DETAILS = 'FETCH_OUTLET_DETAILS';

export const GUEST_FEEDBACK = 'GUEST_FEEDBACK';

export const GET_BRAND_FEEDBACK_SETUP = 'GET_BRAND_FEEDBACK_SETUP';

export const CLEAR_GUEST_FEEDBACK = 'CLEAR_GUEST_FEEDBACK';

export const DISPATCH_IS_REWARDS_CLUB_FLOW = 'DISPATCH_IS_REWARDS_CLUB_FLOW';

export const CREATE_BRAND_GUEST = 'CREATE_BRAND_GUEST';

export const FETCH_MENU_ITEM_VARIATIONS = 'FETCH_MENU_ITEM_VARIATIONS';

export const GET_ORDER_FEEDBACK_SETUP = 'GET_ORDER_FEEDBACK_SETUP';

export const FETCH_ORDER_FEEDBACK = 'FETCH_ORDER_FEEDBACK';

export const GET_ORDER_FEEDBACK_RESPONSE = 'GET_ORDER_FEEDBACK_RESPONSE';

export const CLEAR_FETCH_ORDER_FEEDBACK = 'CLEAR_FETCH_ORDER_FEEDBACK';
