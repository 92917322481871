import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import lifecycle from 'page-lifecycle/dist/lifecycle.es5';

import rootStore from './store/store';
import smoothscroll from 'smoothscroll-polyfill';

import {
  storeMaxAge,
  userActivityTrackingDebounceTime,
  storeExpiryEnabled,
  sessionTimeoutPurgeInclusionRoutes,
} from './constants/globals';

import { activateUserActivityTracker } from './utils/helpers';
import { markPersistorForPurgeAction } from './actions/app.action';

import App from './containers';

import './index.scss';

// If the page (browsing context) was discarded by the browser, redirect to location.origin which will further redirect to the contextual home when App loads
if (lifecycle && lifecycle.pageWasDiscarded) {
  window.location.replace(window.location.origin);
}

// inject the smooth scroll polyfill
smoothscroll.polyfill();

const { store } = rootStore;

// Session state reset flow
if (!!storeExpiryEnabled) {
  // Start tracking user activity with a debounce
  activateUserActivityTracker(() => {
    const currentTime = Date.now();
    const userLastActiveTime = JSON.parse(sessionStorage.getItem('userLastActive'));

    // Check if the current route is allowed for the session timeout
    const currentRoute = window.location.pathname.split('/')[1] || '';
    const isCurrentRouteSessionTimeoutScoped =
      sessionTimeoutPurgeInclusionRoutes.includes(currentRoute);

    // Check whether the user has been idle for more than the configured max age of the store
    if (
      !!userLastActiveTime &&
      currentTime - userLastActiveTime > storeMaxAge &&
      isCurrentRouteSessionTimeoutScoped
    ) {
      store.dispatch(markPersistorForPurgeAction(true));
    }
    // Update userLastActive cookie every time the user activity is registered under debounce
    sessionStorage.setItem('userLastActive', currentTime);
  }, userActivityTrackingDebounceTime);
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
