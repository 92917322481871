import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
class Animatable extends Component {
  render() {
    const { loop, autoplay, animationData, renderer, sourceUrl } = this.props;
    const defaultOptions = {
      loop,
      autoplay,
      animationData: animationData.default,
      renderer,
      path: sourceUrl,
    };

    return <Lottie options={defaultOptions} isClickToPauseDisabled={true} />;
  }
}

Animatable.propTypes = {
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  animationData: PropTypes.any.isRequired,
  renderer: PropTypes.string.isRequired,
  sourceUrl: PropTypes.string.isRequired,
};

Animatable.defaultProps = {
  loop: true,
  autoplay: true,
  animationData: '',
  renderer: 'svg',
  sourceUrl: '',
};

export default Animatable;
